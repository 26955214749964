import {HttpHeaders} from '@angular/common/http';
import {Headers} from '@angular/http';

import {OAuthService} from '../../pages/user/services/oauth.service';

export abstract class AuthenticatedWebService {

    constructor(private oauthService:OAuthService) {
    }

    get httpHeaders() {
        let accessToken = this.oauthService.getTokens().accessToken;
        let httpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${accessToken}`);
        return httpHeaders;
    }

    get httpHeadersFormData() {
        let accessToken = this.oauthService.getTokens().accessToken;
        let httpHeaders = new HttpHeaders()
            .set('Authorization', `Bearer ${accessToken}`);
        return httpHeaders;
    }

    //Precisa apagar
    get headers() {
        let accessToken = this.oauthService.getTokens().accessToken;
        let headers = new Headers();
        headers.set('Content-Type', 'application/json');
        headers.set('Authorization', `Bearer ${accessToken}`);
        return headers;
    }

    get headersFormData() {
        let accessToken = this.oauthService.getTokens().accessToken;
        let headers = new Headers();
        headers.set('Authorization', `Bearer ${accessToken}`);
        return headers;
    }

}
