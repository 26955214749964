import { Alert, AlertType } from './../icp-alert.model';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'icp-alert-item',
  templateUrl: './icp-alert-item.component.html',
  styleUrls: ['./icp-alert-item.component.scss']
})
export class IcpAlertItemComponent implements OnInit {
  @Input() placement: string;
  @Input() alerts = [];
  @Output() close: EventEmitter<Alert> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClose(alert: Alert) {
    this.close.emit(alert);
  }
}
