import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Identifiable } from '../models/identifiable.model';
import { HttpInterceptor } from '../interceptors/http-interceptor.service';
import { AuthenticatedWebService } from './authenticated.web.service';
import { OAuthService } from '../../pages/user/services/oauth.service';
import { BASE_URL } from '../tokens/injection-token.config';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class BaseWebService<T> extends AuthenticatedWebService {

    public number: number = 2;
    public readonly BASE_URL:string = environment.api.base_api;

    protected route:string;

    constructor(
        @Inject(BASE_URL) url:string,
        protected http:HttpInterceptor,
        oauthService: OAuthService
    ){
        super(oauthService);
        this.route = url;
    }

    create(data:any){
        let url = this.BASE_URL + this.route;

        return this.http
            .post(url, data, { headers: this.httpHeaders })
            .pipe(
                map((res: any) => res)
            );
    }

    update(data:Identifiable){
        let url = this.BASE_URL + this.route + '/' + data.id;
        return this.http
            .put(url, data, { headers: this.httpHeaders })
            .pipe(
                map((res: any) => res)
            );
    }

    patch(data:any, id:any, organizacao_id:any){
        let url = this.BASE_URL + this.route + '/' + id;

        return this.http
            .patch(url, data, { headers: this.httpHeaders })
            .pipe(
                map((res: any) => res)
            );
    }

    get(params:HttpParams){
        let url = this.BASE_URL + this.route;

        return this.http
        .get(url, { headers: this.httpHeaders, params })
        .pipe(
            map((res: any) => res)
        );
    }

    getId(id, params?){
        let url = this.BASE_URL + this.route + '/' + id;

        return this.http
            .get(url, { headers: this.httpHeaders, params })
            .pipe(
                map((res: any) => res)
            );
    }

    list(params?:HttpParams){
        let url = this.BASE_URL + this.route;

        return this.http
            .get(url, { params: params, headers: this.httpHeaders })
            .pipe(
                map((res: any) => res)
            );
    }

    delete(id){
        let url = this.BASE_URL + this.route + '/' + id;

        return this.http
            .delete(url, { headers: this.httpHeaders })
            .pipe(
                map((res: any) => res)
            );
    }

    getCep(cep) {
        let headers = new HttpHeaders();
        var url = this.route + cep + '/json';
        return this.http
            .get(url, { headers: headers })
            .pipe(
                map((res: any) => res)
            );
    }
}
