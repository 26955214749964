export class GlobalConfig{
    public static readonly MOUSEENTER_TIMEOUT = 1000;
    public static readonly UNIDADE_TIPO_JURIDICA = 'a14fba1e-936f-4361-9af7-25043a5b5801';
    public static readonly UNIDADE_TIPO_FISICA = '0e73d9ae-61dd-488e-bbd9-e5635974e4f0';
    public static readonly UNIDADE_CONTATO_TIPO_RESPONSAVEL = '46db2887-79c9-433f-a626-af731371f5e8';
    public static readonly UNIDADE_CONTATO_TIPO_OUTRO = 'a2b6ffc5-89d3-43b5-8f80-1395a2fe6c31';
    public static readonly UNIDADE_CONTATO_STATUS_INATIVO = 'b2ae4a27-fbd4-4ea2-851d-2cd1668c5ddc';
    public static readonly PLANO_PARTICULAR_TIPO_STANDARD = '1c3c08ef-5b71-4141-be2a-92e37655c95d';
    public static readonly PLANO_PARTICULAR_TIPO_PRO = 'a3f49196-f2ee-47da-9896-bf8b8ef20e09';
    public static readonly STATUS_PARTICULAR_INATIVO = '698c0fa3-4884-41e8-ab62-aba370a254f0';
    public static readonly STATUS_PARTICULAR_ATIVO = 'bd497249-132e-4a60-98e4-5b1dcc5b69b1';
    public static readonly VENDEDOR_CONTA_POUPANCA = '49d6bcc5-e541-4244-94a9-72e6224565a7';
    public static readonly VENDEDOR_CONTA_CORRENTE = 'd857e878-edb6-4544-9fd1-298a51e8be1e';

    public static readonly TRANSACAO_STATUS_APROVADA = '52ce753a-3fea-4c51-aed7-545b8d02d7be';
    public static readonly TRANSACAO_STATUS_FALHADA = 'f506a85d-e8af-4b7d-8211-51b8ee815803';
    public static readonly TRANSACAO_STATUS_CANCELADA = '5819b64e-4bc3-41b6-afe3-a6b44ed76639';
    public static readonly TRANSACAO_STATUS_PRE_AUTORIZADA = 'c1549d72-1c2a-4c12-9e65-119332c2173b';
    public static readonly TRANSACAO_STATUS_REVERTIDA = '87cb707d-a089-4484-ad99-99b097cd21c2';
    public static readonly TRANSACAO_STATUS_DEVOLVIDA = '3e762023-c8c5-4922-8428-e6199a68ba92';
    public static readonly TRANSACAO_STATUS_PENDENTE = 'c6c253b5-9a01-4e35-847b-65d1980c327f';
    public static readonly TRANSACAO_STATUS_NOVA = 'ac498554-a579-4618-a040-aa0f99eeae7a';
    public static readonly TRANSACAO_STATUS_PARCIALMENTE_DEVOLVIDA = 'db8ad75d-66ef-422a-b9e2-a03d50118d17';
    public static readonly TRANSACAO_STATUS_DISPUTA = 'abf01d73-0d8a-4bda-8be8-ab39d5c18425';
    public static readonly TRANSACAO_STATUS_COBRADO_DE_VOLTA = '66642b7a-598e-4557-a9ed-4817200f76bd';
    
    public static readonly DOCUMENTO_EM_ANALISE = 'ea6ea9c0-8276-4b35-8a27-baf3012424b9';
    public static readonly DOCUMENTO_APROVADO = '23aac931-6c81-45a3-bf41-6c2ed6ee6ee4';
    public static readonly DOCUMENTO_REPROVADO = 'a1e8dd96-e487-4d18-90e5-a03eb4a26632';
    public static readonly DOCUMENTO_TIPO_IDENTIFICACAO = 'bc3779ee-3fe3-4e84-a277-704ecc2c88c4';
    public static readonly DOCUMENTO_TIPO_ATIVIDADE = 'add8f48f-ea2e-4273-90ea-fc12aae4858f';
    public static readonly DOCUMENTO_TIPO_RESIDENCIA = 'fbd29fcc-531b-4a33-aaa1-dce03f998d6b';
    public static readonly DOCUMENTO_TIPO_CNPJ = 'ab315581-fb0a-434a-9f24-bef19e2ca5ef';

    public static readonly ORGANIZACAO_APROVADA = 'df00f5ae-77b3-4fd0-8a58-93a112827be8';

    public static readonly TIPO_PLANO_PARTICULAR_PRO = 'PRO';
    public static readonly TIPO_PLANO_PARTICULAR_STANDARD = 'STD';

    public static readonly ORGANIZACAO_PLANO_BLUE = 'c3b7acc8-a187-4acc-b3e2-fb4a19a7a452';
    public static readonly ORGANIZACAO_PLANO_ORANGE = 'a2699466-06d3-4e99-b5b5-547c914fece8';
    public static readonly DATA_LIMITE_ALTERACAO_PLANO_VENDEDOR = '2018-12-29T00:00:00.000000Z';
}