import { Injectable } from '@angular/core';
import { User } from '../../pages/user/models/user.model';

declare const gtag: Function;

@Injectable({
  providedIn: 'root'
})
export abstract class GoogleAnalyticsService {

  constructor() { }

  trackPageView(page_title: string, page_path?: string): void {
    gtag('event', 'page_view', {
      page_title,
      page_path
    });
  }

  trackPageViewByUser(page_path: string, user?: User): void {
    gtag('event', 'user_page_view', {
      page_path,
      user_id: user? user.id : null,
      user_name: user? user.name : null,
      user_email: user? user.email : null,
      organization_id: (user && user.organizacoes.length > 0)? user.organizacoes[0].id : null,
      organization_name: (user && user.organizacoes.length > 0)? user.organizacoes[0].name : null,
      date: new Date().toUTCString()
    });
  }
}
