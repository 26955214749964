import { Injectable } from '@angular/core';

import { BehaviorSubject ,  Observable } from 'rxjs';

@Injectable()
export class ThemeSharedService {

    private menuCollapsedListener: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    private cadastroWarningListener: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    private emailConfirmedListener: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    private emailNotConfirmedListener: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    private hideMenuListener: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    private documentsWarningListener: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    private genericErrorHandler: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
    ) {}


    setMenuCollapsed(newData:any){
        this.menuCollapsedListener.next(newData);
    }                

    getRefreshData():Observable<any>{
        return this.menuCollapsedListener.asObservable();
    }

    setCadastroWarning(newData:any){
        this.cadastroWarningListener.next(newData);
    }                

    getCadastroWarning():Observable<any>{
        return this.cadastroWarningListener.asObservable();
    }    

    setEmailConfirmed(newData:any){
        this.emailConfirmedListener.next(newData);
    }                

    getEmailConfirmed():Observable<any>{
        return this.emailConfirmedListener.asObservable();
    }        

    setEmailNotConfirmed(newData:any){
        this.emailNotConfirmedListener.next(newData);
    }                

    getEmailNotConfirmed():Observable<any>{
        return this.emailNotConfirmedListener.asObservable();
    }       
    
    setHideMenu(newData:any){
        this.hideMenuListener.next(newData);
    }                

    getHideMenu():Observable<any>{
        return this.hideMenuListener.asObservable();
    }   
    
    setDocumentsWarning(newData:any){
        this.documentsWarningListener.next(newData);
    }                

    getDocumentsWarning():Observable<any>{
        return this.documentsWarningListener.asObservable();
    } 
    
    setGenericErrorHandler(newData:any){
        this.genericErrorHandler.next(newData);
    }                

    getGenericErrorHandler():Observable<any>{
        return this.genericErrorHandler.asObservable();
    }     

}