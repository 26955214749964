<generic-error *ngIf="genericErrorReceived"></generic-error>
<app-menu *ngIf="showMenu"></app-menu>
<div class="app-main" [class.menuCollapsed]="menuCollapsed" [class.noMenu]="!showMenu">
    <div class="maintenance-alert" *ngIf="showAlert">
        O sistema estará indisponível no dia 14/06/2023 a partir das 21:00 horas, para manutenção. Previsão 3 horas.
    </div>

    <div class="app-content">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </div>
</div>
<footer class="al-footer clearfix"></footer>