<div class="not-found">
  <div class="container">
    <div class="row text-center">
      <div class="col-sm-12">
        <span style="color:#0063CF">
  <i class="fas fa-exclamation-triangle fa-6x mb15"></i>
    </span>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-sm-12">
        <p>
          <strong>
            Ops!
          </strong>
        </p>
        <p>
          Parece que não tem nada por aqui.
        </p>
      </div>
    </div>
    <div class="row text-center">
      <button type="button" class="back-btn darkblue-gradient-bg" (click)="goBack()">
        Voltar
      </button>
    </div>
  </div>
</div>
