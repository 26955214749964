export enum MenuPermissionsEnum {
    PLANO_DE_SAUDE = "medico_plano_saude_visualizar",
    ANTECIPACOES = "antecipacao_antecipar",
    GESTAO_FINANCEIRA = "dashboard",
    SIMULADOR_DE_VENDAS = "sell_simulator_calculate",
}

export enum SubmenuConfiguracoesPermissionsEnum {
    VENDEDORES = "vendedor_visualizar",
    MEU_TIME = "dashboard",
}

export enum SubmenuParticularPermissionsEnum {
    PAGAMENTO_ONLINE = "controle_terminal_visualizar",
    TRANSACOES = "transacao_visualizar",
    RECEBIVEIS = "vendedor_visualizar",
}
