import { Component, EventEmitter, Output } from '@angular/core';

import { CacheService } from '../../../../shared/services/cache.service';
import { BaseMenuItemComponent } from '../base-menu-item.component';
import { SubmenuParticularPermissionsEnum } from '../enums/menu-permissions.enum';
import { MenuItemChild } from '../models/menu-item-child.model';
import { MenuUtilsService } from '../services/menu-utils.service';
import { MenuSharedService } from '../services/menu.shared.service';

@Component({
  selector: 'particular-menu-item',
  templateUrl: '../templates/menu-item.html',
  styleUrls: ['../scss/menu-item.scss']
})

export class IclinicPayMenuItem extends BaseMenuItemComponent {
  menuCollapsed: boolean;
  isMobile: boolean = false;
  @Output() onClickSelect = new EventEmitter<any>();
  @Output() onClickItem = new EventEmitter<void>();

  constructor(
    menuSharedService: MenuSharedService,
    private cacheService: CacheService,
    menuUtilsService: MenuUtilsService
  ) {
    // Título, Array de Filhos, SVG, Ativo (sim ou não), altura da lista quando os subitems estiverem à mostra
    super(
      'Particular',
      'particular',
      [
        new MenuItemChild('Pagamento online', '/pages/particular/pagamento-online', false,
          menuUtilsService.userHavePermission(SubmenuParticularPermissionsEnum.PAGAMENTO_ONLINE)),
        new MenuItemChild('Transações', '/pages/particular/transacoes', false,
          menuUtilsService.userHavePermission(SubmenuParticularPermissionsEnum.TRANSACOES)),
        new MenuItemChild('Recebíveis', '/pages/particular/recebiveis', false,
          menuUtilsService.userHavePermission(SubmenuParticularPermissionsEnum.RECEBIVEIS)),
      ],
      `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 0H5C3.9 0 3 0.9 3 2V4C3 5.1 3.9 6 5 6H15C16.1 6 17 5.1 17 4V2C17 0.9 16.1 0 15 0ZM14.5 4H5.5C5.22 4 5 3.78 5 3.5V2.5C5 2.22 5.22 2 5.5 2H14.5C14.78 2 15 2.22 15 2.5V3.5C15 3.78 14.78 4 14.5 4ZM18 20H2C0.9 20 0 19.1 0 18V17H20V18C20 19.1 19.1 20 18 20ZM16.53 8.19C16.21 7.47 15.49 7 14.7 7H5.3C4.51 7 3.79 7.47 3.47 8.19L0 16H20L16.53 8.19ZM7.5 14H6.5C6.22 14 6 13.78 6 13.5C6 13.22 6.22 13 6.5 13H7.5C7.78 13 8 13.22 8 13.5C8 13.78 7.78 14 7.5 14ZM7.5 12H6.5C6.22 12 6 11.78 6 11.5C6 11.22 6.22 11 6.5 11H7.5C7.78 11 8 11.22 8 11.5C8 11.78 7.78 12 7.5 12ZM7.5 10H6.5C6.22 10 6 9.78 6 9.5C6 9.22 6.22 9 6.5 9H7.5C7.78 9 8 9.22 8 9.5C8 9.78 7.78 10 7.5 10ZM10.5 14H9.5C9.22 14 9 13.78 9 13.5C9 13.22 9.22 13 9.5 13H10.5C10.78 13 11 13.22 11 13.5C11 13.78 10.78 14 10.5 14ZM10.5 12H9.5C9.22 12 9 11.78 9 11.5C9 11.22 9.22 11 9.5 11H10.5C10.78 11 11 11.22 11 11.5C11 11.78 10.78 12 10.5 12ZM10.5 10H9.5C9.22 10 9 9.78 9 9.5C9 9.22 9.22 9 9.5 9H10.5C10.78 9 11 9.22 11 9.5C11 9.78 10.78 10 10.5 10ZM13.5 14H12.5C12.22 14 12 13.78 12 13.5C12 13.22 12.22 13 12.5 13H13.5C13.78 13 14 13.22 14 13.5C14 13.78 13.78 14 13.5 14ZM13.5 12H12.5C12.22 12 12 11.78 12 11.5C12 11.22 12.22 11 12.5 11H13.5C13.78 11 14 11.22 14 11.5C14 11.78 13.78 12 13.5 12ZM13.5 10H12.5C12.22 10 12 9.78 12 9.5C12 9.22 12.22 9 12.5 9H13.5C13.78 9 14 9.22 14 9.5C14 9.78 13.78 10 13.5 10Z" fill="#A0CFF8"/>
      </svg>`,
      false,
      false,
      '0px',
      menuSharedService,
      menuUtilsService
    )

    this.menuSharedService.getSubItemsOf().subscribe((title) => {
      if (title === this.title && !this.showChildren) {
        this.showChildren = true;
        this.heightWhenShowingChildren = menuUtilsService.calculateSubmenuHeight(this.children);
      } else {
        this.showChildren = false;
        this.heightWhenShowingChildren = '0px';
      }
    })
  }

  async ngOnInit() {
    let routerData = await this.cacheService.getRouterGuardData().toPromise();
    if (!routerData.data.seller_amount) {
      this.children = [];
      this.route = '/pages/dados-cadastrais/unidade/create/true'
    }

    if (window.innerWidth <= 500 || screen.width <= 500) {
      this.isMobile = true;
    }

    this.menuSharedService.getMenuCollapsed()
      .subscribe(menuCollapsed => {
        this.menuCollapsed = menuCollapsed;
        this.heightWhenShowingChildren = menuCollapsed || this.isMobile ? '0px' : this.heightWhenShowingChildren;

        if (this.menuCollapsed && this.showChildren) {
          this.showChildren = false;
        }
      });
  }

  toggleItemChildren() {
    super.toggleItemChildren();
    this.onClickSelect.emit({ title: this.title, children: this.children });
    this.isMobile = window.innerWidth <= 500 || screen.width <= 500 ? true : false;
  }

  menuItemClicked() {
    super.menuItemClicked();
    this.onClickItem.emit();
  }
};
