import { Injectable } from "@angular/core";

import { LocalStorageService } from "./../../pages/user/services/local-storage.service";

type TRouterLink = {
  url: string;
  sellerId?: string;
};

@Injectable()
export class StepService {
  routerNavigate = {
    DASHBOARD: this.dashboard.bind(this),
    SEGMENTATION: this.segmentation.bind(this),
    ACCOUNT: this.account.bind(this),
    DOCUMENTS: this.documents.bind(this),
    PLAN: this.plan.bind(this),
    OPERATIONAL_AGREEMENT: this.operational_agreement.bind(this),
  };

  constructor(private localStorage: LocalStorageService) {}

  getRouterLink(step: string, sellerId: string): TRouterLink {
    return this.routerNavigate[step](sellerId);
  }

  dashboard(): TRouterLink {
    const hasRoleDashboard = this.localStorage
      .getUserPermissions()
      .includes("dashboard");

    const notBasicRoles =
      !this.localStorage.getRole().includes("iniciante") &&
      !this.localStorage.getRole().includes("basico");

    if (hasRoleDashboard && notBasicRoles) {
      return {
        url: "/pages/gestao-financeira/dashboard/resumo",
      };
    }

    return {
      url: "/pages/simulador-vendas",
    };
  }

  segmentation(): TRouterLink {
    return {
      url: "/onboarding/fonte-recebimento",
    };
  }

  account(sellerId: string): TRouterLink {
    return {
      url: "/onboarding/cadastro-cartao-pix/conta",
      sellerId
    };
  }

  documents(sellerId: string): TRouterLink {
    return {
      url: "/onboarding/cadastro-cartao-pix/documentos",
      sellerId
    };
  } 

  plan(sellerId: string): TRouterLink {
    return {
      url: "/onboarding/modalidade-recebimento/selecao-modalidade",
      sellerId
    };
  }

  operational_agreement(sellerId: string): TRouterLink {
    return {
      url: "/onboarding/modalidade-recebimento/acordo-operacional",
      sellerId
    };
  }
}
