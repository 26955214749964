import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../user/services/local-storage.service';
import { ThemeSharedService } from '../../../theme/services/theme.shared.service';
import { CacheService } from '../../..//shared/services/cache.service';
import { AuthService } from '../../user/services/auth.service';
import { MenuSharedService } from '../../../theme/components/menu/services/menu.shared.service';
import { DataSharingService } from '../../user/services/data-sharing.web.service';
import { AppRoutes } from '../../../shared/constants/routes/routes.constant';

@Component({
  selector: 'erro-assinatura',
  templateUrl: '../templates/erro-assinatura.html',
  styleUrls: ['../scss/erro-assinatura.scss']
})

export class ErroAssinatura implements OnInit, AfterViewInit {
  constructor(
    private localStorage: LocalStorageService,
    private themeService: ThemeSharedService,
    private cacheService: CacheService,
    private auth: AuthService,
    private menuSharedService: MenuSharedService,
    private dataSharingService: DataSharingService,
    private router: Router
  ) { }

  ngOnInit() { 
    this.cacheService.getOrganizacaoAssinatura().subscribe((res) => {
      if (!res.inadimplente) {
        this.router.navigate(['/pages/gestao-financeira/dashboard/resumo'])
        this.themeService.setHideMenu(true);
      };
    })
  }

  ngAfterViewInit(): void {
    this.themeService.setHideMenu(false);
  }

  logout() {
    this.cacheService.clearCache();
    this.router.navigate([AppRoutes.LOGOUT]);
    this.auth.logout();
    this.menuSharedService.setMenuCollapsed(true);
    this.dataSharingService.setOrganizacaoData(null);
    this.dataSharingService.setTerminalLiberacaoData(null);
  }  
}