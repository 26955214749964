import { Pagination } from './pagination.model';

export class SearchResult {
   object: Array<any>;
   pagination: Pagination;

   constructor(object?:Array<any>, pagination?:Pagination) {
       this.object = object;
       this.pagination = pagination;
   }
}