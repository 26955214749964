<div [ngClass]="placement">
  <ng-container *ngFor="let alert of alerts">
		<div
			class="alert alert-dismissable"
			[ngClass]="'alert-' + alert.type"
			[class.fade]="alert.fade"
		>
			<span class="content">
				<span class="material-symbols-outlined alert-icon">{{alert.icon}}</span>
				<span class="message" [innerHTML]="alert.message"></span>
			</span>
			<span *ngIf="!alert.autoClose" class="material-symbols-outlined close" (click)="onClose(alert)">close</span>
		</div>
	</ng-container>
</div>