<div class="text-center queue-container">
    <div class="row">
        <div class="col-sm-12">
            <div class="title">
                <span>Olá,</span><br>
                <span class="bold">{{ user.name }}</span>
            </div>
        </div>
    </div>
    <div class="row mt20 mb20">
        <div class="col-sm-12">
            <strong>
                Nossa plataforma é exclusiva!
            </strong>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <p>
                Sua solicitação de convite foi feita com sucesso e, no momento,<br>
                você se encontra <strong>em espera para desfrutrar das soluções iClinic Pay.</strong><br>
                Entraremos em contato assim que sua requisição for aprovada!
            </p><br>
            <p>
                Ah! Caso tenha um colega da área de saúde que já seja cliente iClinic Pay<br>
                <strong>solicite um convite para otimizar o seu processo.</strong><br> 
                <strong class="light-blue">
                    Um pedido de um colega tem mais chances de ser aprovado!
                </strong>
            </p>
        </div>
    </div>
    <div class="row mt20 mb10">
        <div class="col-sm-12">
            <strong>Obrigado pelo interesse!</strong>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <button type="button" class="back-btn darkblue-gradient-bg white-font mt30" [routerLink]="['/login']">
                Sair
            </button>              
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <img src="../../../assets/img/referral-queue.svg" alt="" class="referral-img">
        </div>
    </div>
</div>