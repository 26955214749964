<div class="menu-item" routerLinkActive #rla="routerLinkActive" [class.inactive]="!rla.isActive" [class.active-parent]="rla.isActive" [class.collapsed]="menuCollapsed">
  <div class="menu-item-outer-container" (click)="toggleItemChildren()">
    <div class="display-flex">
      <a class="item-link" *ngIf="children.length">
        <span class="svg-container" [innerHtml]="icon | safeHtml"></span>
        <span class="title">{{ title }}</span>
      </a>
      <a class="item-link" [routerLink]="[route]" *ngIf="!children.length" (click)="menuItemClicked()">
        <span class="svg-container" [innerHtml]="icon | safeHtml"></span>
        <span class="title">{{ title }}</span>
      </a>
    </div>
    <span class="material-icons" *ngIf="children.length" [ngClass]="{'rotate-open': showChildren, 'rotate-close': !showChildren}">{{ isMobile ? 'keyboard_arrow_right' : 'expand_more'}}</span>
  </div>
  <ul *ngIf="children.length" class="children-container" [style.height]="heightWhenShowingChildren">
    <ng-container *ngFor="let child of children">
      <li routerLinkActive="active-child" *ngIf="child.visible">
        <a [routerLink]="[child.route]" (click)="menuItemClicked()">
          <span class="hidden" routerLinkActive="list-visible">• </span>
          <span>{{ child.title }}</span>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
