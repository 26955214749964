import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {OAuthService} from './oauth.service';
import {UserWebService} from './user.web.service';
import {User} from '../models/user.model';
import {UserStorageService} from './user-storage.service';
import {flatMap, map} from 'rxjs/operators';
import {ThemeSharedService} from '../../../theme/services/theme.shared.service';
import getImageUrl from '../../../shared/utils/get-profile-pic';
import * as Sentry from '@sentry/browser';
import {OrganizacaoDataService} from './organizacao-data.web.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class LoginService {

  public check: boolean = false;
  public hasAdditionalData: boolean = false;

  private loggedIn: boolean = false;

  constructor(
    private oauthService: OAuthService,
    private userService: UserWebService,
    private userStorageService: UserStorageService,
    private themeService: ThemeSharedService,
    private organizacaoService: OrganizacaoDataService
  ) {
    let tokens = this.oauthService.getTokens();
    this.loggedIn = !!tokens.accessToken && !!tokens.refreshToken;
  }

  isLogged(): Observable<any> {
    const self = this.userService.getSelf();
    return self.pipe(
      map((res) => res)
    );
  }

  login(user: User): Observable<any> {
    return this.oauthService
      .getAccessToken(user)
      .pipe(
        map((res) => this.setTokens(res)),
        flatMap((res) => this.userService.getSelf(true)),
        map((res: any) => {
          var _paq = window['_paq'] || [];
          if (res.role_name === 'admin') {
            _paq.push(['optUserOut']);
          }
          if (!res.data_confirmacao_email) {
            this.themeService.setEmailNotConfirmed(true);
          }
          localStorage.setItem('referral', (res.organizacoes[0].referral));
          return res;
        }),
        map((user) => {
          let newUser = user;
          newUser['profile_photo_url'] = getImageUrl(newUser.photo_id, 55, 55);

          Sentry.configureScope(scope => {
            scope.setUser({
              id: user.id,
              username: user.name,
              email: user.email
            });
          });

          return newUser;
        }),
        flatMap((user) => this.organizacaoService.setUserCobranca(user)),
        map((res) => this.storeUser(res)),
        map((res) => this.loginSuccess(res))
      ).pipe(
      catchError((err) => this.loginFailure(err)));
  }

  refreshLogin(refreshToken: string): Observable<any> {
    return this.oauthService
      .getRefreshToken(refreshToken)
      .pipe(
        map((res) => this.setTokens(res))
      );
  }

  logout() {
    this.oauthService.removeTokens();
  }

  setTokens(response: any) {
    this.oauthService.setTokens(response.access_token, response.refresh_token);
    return response;
  }

  loginFailure(error: any) {
    this.oauthService.removeTokens();
    this.userStorageService.removeUser();
    return observableThrowError(error);
  }

  loginSuccess(response: any) {
    this.loggedIn = true;
    return response;
  }

  storeUser(response: any) {
    this.userStorageService.setUser(response);
    return response;
  }

}
