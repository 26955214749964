import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Alert, AlertType, AlertIcon } from './icp-alert.model';
@Injectable({
  providedIn: 'root'
})
export class IcpAlertService {
  private subject = new Subject<Alert>();

  public getAlerts(): Observable<Alert> {
    return this.subject.asObservable();
  }

  public execute(alert: Alert) {
    this.subject.next(alert);
  }

  success(message: string, options?: any) {
    this.execute(new Alert({ ...options, icon: AlertIcon.Success, type: AlertType.Success, message }))
  }

  error(message: string, options?: any) {
    this.execute(new Alert({ ...options, icon: AlertIcon.Error, type: AlertType.Error, message }))
  }

  info(message?: string, options?: any) {
    this.execute(new Alert({ ...options, icon: AlertIcon.Info, type: AlertType.Info, message }));
  }

  warn(message?: string, options?: any) {
    this.execute(new Alert({ ...options, icon: AlertIcon.Warning, type: AlertType.Warning, message }));
  }
}