import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpInterceptor } from '../../../shared/interceptors/http-interceptor.service';
import { AuthenticatedWebService } from '../../../shared/services/authenticated.web.service';
import { OAuthService } from './oauth.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { OrganizacaoOnboardingStep } from '../models/organizacao.model';

@Injectable()
export class OrganizacaoOnboardingStepService extends AuthenticatedWebService {

    constructor(
        private localStorage: LocalStorageService,
        private http: HttpInterceptor,
        oauthService:OAuthService,
        private router: Router,
    ) {
        super(oauthService);
    }

    getOnboardingStepData(): Observable<OrganizacaoOnboardingStep> {
        const organizacaoId = this.localStorage.getOrganizacaoId();

        if (!organizacaoId) this.router.navigate(["login"]);

        const  url = `${environment.api.base_url}api/v2/organizations/${organizacaoId}/onboarding-step`;

        return this.http
        .get(url, {headers: this.httpHeaders })
        .pipe(
            map((res: any) => res.data)
        );
    }
}
