import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivationEnd, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import * as Snowplow from './snowplow.service';

@Injectable({
  providedIn: 'root'
})
export class RouteInterceptorService {
  currentRoute: string;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.rootRoute(this.route)),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
    ).subscribe((route: ActivatedRoute) => {
      Snowplow.trackPageView({title: route.snapshot?.data?.title});
    });
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
