import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeWhile } from 'rxjs/operators';

import { User } from '../../../pages/user/models/user.model';
import { AuthService } from '../../../pages/user/services/auth.service';
import { DataSharingService } from '../../../pages/user/services/data-sharing.web.service';
import { LocalStorageService } from '../../../pages/user/services/local-storage.service';
import { AppRoutes } from '../../../shared/constants/routes/routes.constant';
import { CacheService } from '../../../shared/services/cache.service';
import getImageUrl from '../../../shared/utils/get-profile-pic';
import { Global } from './../../../shared/constants/global.constant';
import { MenuItemNameEnum } from './enums/menu-item-name.enum';
import { MenuPermissionsEnum } from './enums/menu-permissions.enum';
import { MENU_ITEMS } from './menu-items';
import { MenuUtilsService } from './services/menu-utils.service';
import { MenuSharedService } from './services/menu.shared.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  menuCollapsed: boolean = true;
  menuItems: Array<any>;
  subMenu: string;
  loggedUser: User = new User();
  private alive: boolean = true;
  avatarUrl: any;
  showMenu: boolean = false;
  hasMenuData: boolean = false;
  showTheseMenuItens: Map<string, boolean> = new Map();
  referral: string;
  role: string;
  menuSelected: boolean = false;
  isMobile: boolean = false;
  title: string;
  children;
  isOpen: boolean = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private localStorage: LocalStorageService,
    private dataSharingService: DataSharingService,
    private sanitizer: DomSanitizer,
    private cacheService: CacheService,
    private menuSharedService: MenuSharedService,
    private menuUtilsService: MenuUtilsService,
  ) {
    this.dataSharingService.getLoggedUserAvatar().subscribe((url) => {
      this.avatarUrl = url;
    })
    this.menuSharedService.getMenuCollapsed().subscribe((value) => {
      this.menuCollapsed = value;
    })

    this.showTheseMenuItens.set(MenuItemNameEnum.PARTICULAR, true);
    this.showTheseMenuItens.set(MenuItemNameEnum.ANTECIPACOES, true);
    this.showTheseMenuItens.set(MenuItemNameEnum.GESTAO_FINANCEIRA, true);
    this.showTheseMenuItens.set(MenuItemNameEnum.CONVENIOS, true);
    this.showTheseMenuItens.set(MenuItemNameEnum.SIMULADOR_DE_VENDAS, true);
    this.showTheseMenuItens.set(MenuItemNameEnum.PLANO_DE_SAUDE, true);
  }

  ngOnInit() {
    this.referral = localStorage.getItem('referral').toLowerCase();
    this.role = JSON.parse(localStorage.getItem('user')).role_name;
    this.menuItems = null;
    this.loggedUser = this.localStorage.getObject('user');

    this.checkUserPermissions();
    this.checkMenuForActiveItem(this.router.url);

    if (this.loggedUser.profile_photo_url)
      this.dataSharingService.setLoggedUserAvatar(this.sanitizer.bypassSecurityTrustStyle(`url(${this.loggedUser.profile_photo_url})`));
    else
      this.avatarUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${getImageUrl(this.loggedUser.photo_id, 55, 55)})`);

    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).pipe(takeWhile(() => this.alive))
      .subscribe((event: any) => {
        this.checkMenuForActiveItem(event.urlAfterRedirects);
        this.handleMenuItems();
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  checkUserPermissions() {
    let _newMenuItems = JSON.parse(JSON.stringify(MENU_ITEMS));
    const _permission = this.localStorage.getUserPermissions();

    _newMenuItems = _newMenuItems
      .filter((item) => {
        if (item.permissions)
          return _permission.includes(item.permissions[0]);
        else
          return true;
      })

    _newMenuItems = _newMenuItems.map((item: any) => {
      const _newItem = item;
      if (_newItem.children && _newItem.children.length) {
        _newItem.children = _newItem.children.filter((child) => !child.permissions || _permission.includes(child.permissions[0]));
      }
      return _newItem;
    });

    this.menuItems = _newMenuItems;
    this.handleMenuItems();
  }

  checkMenuForActiveItem(_url) {
    this.menuItems.forEach((item) => {
      item.inactive = item.path !== _url;

      if (item.children) {
        item.children.forEach((children) => {
          children.inactive = children.path !== _url;
          item.inactive = !item.children.some(e => e.inactive === false);
        });
      }
    });
  }

  collapseMenu(_onlyExpand: boolean) {
    if (_onlyExpand) {
      this.menuSharedService.setMenuCollapsed(false);
      return this.menuCollapsed = false
    };

    this.menuCollapsed = !this.menuCollapsed;
    if (this.menuCollapsed) {
      this.isOpen = false;
    }
    this.menuSharedService.setMenuCollapsed(this.menuCollapsed);
    this.subMenu = 'none';
  }

  expandWhenItemClicked() {
    this.menuCollapsed = true;
    this.isOpen = false;
    this.subMenu = 'none';
    this.menuSharedService.setMenuCollapsed(this.menuCollapsed);
  }

  toggleSubMenu(subMenuIndex: string) {
    this.subMenu = this.subMenu === subMenuIndex ? 'none' : subMenuIndex;
    this.menuCollapsed = false;
    this.menuSharedService.setMenuCollapsed(this.menuCollapsed);
  }

  logout() {
    this.menuSharedService.setMenuCollapsed(true);
    this.dataSharingService.setOrganizacaoData(null);
    this.dataSharingService.setTerminalLiberacaoData(null);

    this.cacheService.clearCache();
    this.router.navigate([AppRoutes.LOGOUT]);
    this.auth.logout();
  }

  handleMenuItems() {
    this.cacheService.getRouterGuardData().subscribe(_routerGuard => {
      this.hasMenuData = true;
      this.showMenu = true;

      if (_routerGuard) {
        this.showTheseMenuItens.set(MenuItemNameEnum.ANTECIPACOES,
          this.menuUtilsService.userHavePermission(MenuPermissionsEnum.ANTECIPACOES));
        this.showTheseMenuItens.set(MenuItemNameEnum.PLANO_DE_SAUDE,
          this.menuUtilsService.userHavePermission(MenuPermissionsEnum.PLANO_DE_SAUDE));
        this.showTheseMenuItens.set(MenuItemNameEnum.GESTAO_FINANCEIRA,
          this.menuUtilsService.userHavePermission(MenuPermissionsEnum.GESTAO_FINANCEIRA));
        this.showTheseMenuItens.set(MenuItemNameEnum.SIMULADOR_DE_VENDAS,
          this.menuUtilsService.userHavePermission(MenuPermissionsEnum.SIMULADOR_DE_VENDAS));

        const existsBatchAnticipation = _routerGuard.data.batch_anticipation_amount > 0;
        const existsActiveSeller = _routerGuard.data.sellers.some(seller => seller.seller_status.id === Global.VENDEDOR_ATIVO);

        if (!existsBatchAnticipation && !existsActiveSeller ) {
          this.showTheseMenuItens.set(MenuItemNameEnum.ANTECIPACOES, false);
        }

        if (!existsActiveSeller) {
          this.showTheseMenuItens.set(MenuItemNameEnum.PARTICULAR, false);
          this.showTheseMenuItens.set(MenuItemNameEnum.GESTAO_FINANCEIRA, false);
        }
      }
    });
  }

  onClickSelect(data) {
    this.children = data.children;

    if (window.innerWidth <= 500 || screen.width <= 500) {
      this.isOpen = !this.isOpen;
      this.title = data.title;
    }
  }

  onClickItem() {
    this.isOpen = false;
    this.title = '';
  }

  toggleItemChildren() {
    this.menuSharedService.setSubItemsOf(this.title);
    this.menuSharedService.setMenuCollapsed(false)
    this.isOpen = !this.isOpen;
  }

  menuItemClicked() {
    this.menuSharedService.setMenuCollapsed(true);
    this.menuSharedService.setSubItemsOf('');
    this.isOpen = false;
  }
}
