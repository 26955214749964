import { environment } from './../../../environments/environment';
import { LocalStorageService } from './../../pages/user/services/local-storage.service';
import { Injectable } from "@angular/core";
import { Intercom } from "ng-intercom";

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  constructor(
    private localStorage: LocalStorageService,
    public intercom: Intercom) {

  }

  init() {
    const user = this.localStorage.getObject("user");

    if (user && user.email && user.name) {
      this.intercom.hide();
      this.intercom.boot({
        app_id: environment.intercom_app_id,
        email: user.email,
        ['name']: user.name,
        widget: {
          "activator": "#intercom"
        }
      });
    }
    else {
      this.reset();
    }
  }

  reset() {
    this.intercom.shutdown();
    this.clearStorageAndCookies();
    
    this.intercom.boot({
      app_id: environment.intercom_app_id,
      widget: {
        "activator": "#intercom"
      }
    });
  }
  
  clearStorageAndCookies() {
    window.localStorage.removeItem('intercom.intercom-state');
    window.sessionStorage.removeItem('intercom.played-notifications');
    document.cookie = `intercom-id-${environment.intercom_app_id}=;`
    document.cookie = `intercom-session-${environment.intercom_app_id}=;`
  }
}