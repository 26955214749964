export class APIRoutes{
    public static readonly OAUTH = 'oauth/token';
    public static readonly SELF_USER = 'user/self';
    public static readonly USER_LIST = 'user';
    public static readonly EMPRESTIMO = 'emprestimo';
    public static readonly ORGANIZACAO = 'organizacao';
    public static readonly CONFIGURACAO_EMPRESTIMO = 'configuracao_emprestimo';
    public static readonly FORGOT_PASSWORD = 'password/email/organizacao';
    public static readonly RESET_PASSWORD = 'password/reset';
    public static readonly USER_REGISTER = 'organizacao';
    public static readonly VERIFY_ORGANIZACAO_EMAIL = 'organizacao/user/get-particular-email';
    public static readonly UNIDADE = 'unidade';
    public static readonly PARTICULAR = 'unidade/particular';
    public static readonly UNIDADE_ENDERECO = 'unidade_endereco';
    public static readonly UNIDADE_CONTATO = 'unidade_contato';
    public static readonly UNIDADE_CONTA = 'unidade_conta';
    public static readonly BANCO = 'banco';
    public static readonly LOTE = 'lote';
    public static readonly MEDICO = 'medico';
    public static readonly MEDICO_INFO = 'medico_informacao_profissional';
    public static readonly CONSELHO_PROFISSIONAL = 'conselho_profissional';
    public static readonly CONTATO_MEDICO = 'medico_contato';
    public static readonly PLANO_SAUDE = 'plano_saude';
    public static readonly PLANO_SAUDE_LOGIN = 'plano_saude_login';
    public static readonly MEDICO_PLANO = 'medico_plano_saude';
    public static readonly ESPECIALIDADE_MEDICA = 'especialidade_medica';
    public static readonly MEDICO_PLANO_ESPECIALIDADE = 'medico_plano_saude_especialidade_medica';
    public static readonly MEDICO_PLANO_DIA_ENVIO = 'medico_plano_saude_dia_envio';
    public static readonly ANTECIPACAO = 'emprestimo';
    public static readonly ANTECIPACAO_FATURA = 'emprestimo/showAntecipacaoFatura';
    public static readonly ANTECIPACAO_EMPRESTAR = 'emprestimo/emprestar';
    public static readonly ANTECIPACAO_TOTAL = 'emprestimo/showAntecipacaoTotal';
    public static readonly ANTECIPACAO_TOTAL_EMPRESTAR = 'emprestimo/emprestarTotal';
    public static readonly OPORTUNIDADE = 'dashboard/oportunidade';
    public static readonly RECEBIMENTO = 'dashboard/recebimento';
    public static readonly GLOSAS = 'dashboard/glosa_mensal';
    public static readonly PRODUCAO = 'dashboard/producao_recebimento';
    public static readonly FATURAMENTO = 'dashboard/faturamento_por_plano';
    public static readonly TOTAL_ANTECIPADO_PLANO = 'dashboard/total_antecipado_por_plano';
    public static readonly ATENDIMENTO_PLANO = 'dashboard/atendimento_por_plano';
    public static readonly VALOR_LIBERADO_PLANO = 'dashboard/valor_liberado_por_plano';
    public static readonly RESUMO_MENSAL = 'dashboard/resumo_mensal';
    public static readonly MEDICO_PLANO_SAUDE_CONTRATO = 'medico_plano_saude_contrato';
    public static readonly UPLOAD = 'upload';
    public static readonly LOTE_SYNC_START = 'lote/cronRemoteSync';
    public static readonly REMOTE_SYNC_START = 'lote/remoteSync';
    public static readonly LOTE_SYNC_STATUS = 'lote_sync_status';
    public static readonly RECEBIMENTO_MEDIA = 'dashboard/recebimento_media';
    public static readonly RECEBIMENTO_ACUMULADO_ANUAL = 'dashboard/recebimento_acumulado_anual';
    public static readonly GLOSA_ACUMULADO = 'dashboard/glosa_acumulado_anual';
    public static readonly GLOSA_MEDIA = 'dashboard/glosa_media';
    public static readonly GLOSA_POR_PLANO = 'dashboard/glosa_por_plano';
    public static readonly GLOSA_ALL = 'dashboard/glosa/all';
    public static readonly FATURAMENTO_MEDIA = 'dashboard/faturamento_media';
    public static readonly FATURAMENTO_ACUMULADO_ANUAL = 'dashboard/faturamento_acumulado_anual';
    public static readonly FATURAMENTO_ALL = 'dashboard/faturamento/all';
    public static readonly PAGAMENTO_ALL = 'dashboard/recebimento/all';
    public static readonly PRAZO_PAGAMENTO_MEDIA = 'dashboard/prazo_pagamento_em_dias/media/mensal';
    public static readonly PRAZO_PAGAMENTO_ANUAL = 'dashboard/prazo_pagamento_em_dias/acumulado/anual';
    public static readonly PRAZO_RECEBIMENTO_EM_DIAS = 'dashboard/prazo_recebimento_em_dias';
    public static readonly PRAZO_PAGAMENTO_EM_DIAS = 'dashboard/prazo_pagamento_em_dias';
    public static readonly CHANGE_PASSWORD = 'user/change/password';
    public static readonly ESTATISTICA = 'estatistica';
    public static readonly CONFIRMACAO_EMAIL = 'user/email/confirmacao';
    public static readonly REENVIO_EMAIL = 'user/email/reenvio/confimacao';
    public static readonly GLOSA_GERAL = 'dashboard/glosa_geral';
    public static readonly TRANSACOES = 'transacao';
    public static readonly VENDEDOR_PARTICULAR = 'vendedor';
    public static readonly VENDEDOR_PARTICULAR_UPDATE = 'particular/update';
    public static readonly VENDEDOR_CONTA = 'conta';
    public static readonly RECEBIVEIS = 'recebivel';
    public static readonly DOCUMENTO_PARTICULAR = 'documento';
    public static readonly RECEBIVEIS_POR_DIA = 'recebivel/acumulado_por_dia';
    public static readonly TRANSACAO_LIBERACAO = 'transacao_liberacao_online';
    public static readonly USUARIO_POR_ORGANIZACAO = 'user/organizacao';
    public static readonly PERFIL = 'role';
    public static readonly REFERRAL = 'referral';
    public static readonly CONCILIACAO_TOTAIS = 'conciliacao/totais';
    public static readonly ANTECIPACAO_TRANSACAO_SIMULAR = 'transacao_antecipacao/simular';
    public static readonly ANTECIPACAO_TRANSACAO_ANTECIPAR = 'transacao_antecipacao/antecipar';
    public static readonly ANTECIPACAO_TRANSACAO = 'transacao_antecipacao';
    public static readonly ORGANIZACAO_ASSINATURA_PLANO = 'organizacao_assinatura_plano';
    public static readonly VENDEDOR_PLANO = 'plano';
    public static readonly COBRANCA_MODALIDADE = 'cobranca_modalidade';
    public static readonly COBRANCA_ASSINATURA = 'cobranca_assinatura';
    public static readonly GERAR_COBRANCA_ASSINATURA = 'cobranca_assinatura/gerarAssinatura';
    public static readonly LIMITE_USUARIO = 'limitacao/user';
    public static readonly LIMITE_VENDEDOR = 'limitacao/vendedor';
    public static readonly ULTIMAS_ATIVIDADES = 'ultimas-atividades';
    public static readonly SIMULAR_ANTECIPACAO = 'antecipacao/simular';
    public static readonly ANTECIPACOES_LIST = 'antecipacao';
    public static readonly ANTECIPAR = 'antecipacao/antecipar';
    public static readonly LIMITES_FIDC = 'limites/fidc';
    public static readonly CONTA_VINCULADA = 'conta_vinculada';
    public static readonly DOCUMENT_UPLOAD = 'organizations/{0}/sellers/{1}/documents/upload';
    public static readonly ORGANIZACAO_PLANO_TAXAS = 'plans/get-fees/{0}';
}
