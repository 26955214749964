import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ActivationStart, ActivationEnd } from '@angular/router';
import { MENU_ITEMS } from '../menu/menu-items';
import { filter, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

    menuItems:any = MENU_ITEMS;
    breadcrumbMenuItem:any = null;
    parentMenuItem:any = null;
    father: string;
    son: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {

    }
    
    ngOnInit() {
        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.route),
          map(route => {
            while (route.firstChild) route = route.firstChild
            return route
          }),
          filter(route => route.outlet === 'primary'),
        //   mergeMap(route => route.data)
        ).subscribe((data) => {
                data.data.subscribe(f => this.son = f.title);
                data.parent.data.subscribe(f => this.father = f.title);
            }
        )


        of({}).pipe(
            map(() => this.route),
            map(route => {
                while (route.firstChild) route = route.firstChild
                return route
            }),            
            filter(route => route.outlet === 'primary'),
        ).subscribe((data) => {
            data.data.subscribe(f => this.son = f.title);
            data.parent.data.subscribe(f => this.father = f.title);
        })

    }

}
