<div *ngIf="enabled" class="outdated-browser-warning-container">
    <section>
        <header>
            <h1>Seu navegador está desatualizado</h1>
            
            Olá, como vai? Informamos que o navegador sendo utilizado está desatualizado e 
            pode apresentar problemas na utilização dos serviços da iClinic Pay, além 
            de não possuir as últimas correções de vulnerabilidades de segurança. Não garantimos
            o funcionamento em seu navegador atual e recomendamos fortemente a utilização de um 
            navegador atualizado para que <strong>sua experiência seja mais segura, ágil e completa</strong>.
            <br><br>
            <span *ngIf="!iOS">Abaixo temos navegadores atualizados compatíveis com a plataforma da iClinic Pay:</span>
        </header>
        <main *ngIf="!iOS" class="container">
            <div class="row">
                <div class="browser-selection-item col-sm">
                    <img src="assets/img/browsers/chrome.svg">
                    <div>Google Chrome</div>
                    <a href="https://www.google.com/chrome/?hl=pt-BR">Fazer download</a>
                </div>
                <div class="browser-selection-item col-sm">
                    <img src="assets/img/browsers/firefox.svg">
                    <div>Mozilla Firefox</div>
                    <a href="https://www.mozilla.org/pt-BR/firefox/new/">Fazer download</a>
                </div>
                <div *ngIf="!macOS && !linux" class="browser-selection-item col-sm">
                    <img src="assets/img/browsers/edge.svg">
                    <div>Microsoft Edge</div>
                    <a href="https://www.microsoft.com/pt-br/edge">Fazer download</a>
                </div>
                <div *ngIf="macOS" class="browser-selection-item col-sm">
                    <img 
                        src="assets/img/browsers/safari/128.png"
                        srcset="assets/img/browsers/safari/128.png, assets/img/browsers/safari/256.png 2x, assets/img/browsers/safari/512.png 4x"
                    >
                    <div>Apple Safari</div>
                    <a href="https://support.apple.com/pt-br/HT204416">Fazer download</a>
                </div>
                <div *ngIf="linux" class="browser-selection-item col-sm">
                    <img src="assets/img/browsers/gnomeweb.svg">
                    <div>GNOME Web</div>
                    <a href="https://wiki.gnome.org/Apps/Web">Fazer download</a>
                </div>
            </div>
        </main>
        <footer>
            <div *ngIf="iOS" class="ios-system-update"><a href="https://support.apple.com/pt-br/HT204204">Abrir instruções de atualização da Apple</a></div>
            <div><a (click)="enabled = false">Estou ciente dos riscos e desejo continuar</a></div>
        </footer>
    </section>
</div>