import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";

import { URLSearchParams } from "@angular/http";
import { Router } from "@angular/router";
import {
  flatMap,
  map,
  first,
  take,
  publishReplay,
  refCount,
} from "rxjs/operators";
import { of } from "rxjs";
import { VendedorService } from "../../pages/dados-cadastrais/particular/services/vendedor.service";
import { LocalStorageService } from "../../pages/user/services/local-storage.service";
import { OrganizacaoDataService } from "../../pages/user/services/organizacao-data.web.service";
import { LoginService } from "../../pages/user/services/login.service";
import { UserWebService } from "../../pages/user/services/user.web.service";

@Injectable()
export class CacheService {
  vendedorLimit: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userLimit: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  organizacaoAssinatura: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  organizacaoData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  terminalLiberacaoData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshTokenData: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private vendedorService: VendedorService,
    private userService: UserWebService,
    private organizacaoService: OrganizacaoDataService,
    private loginService: LoginService
  ) {}

  getVendedorLimit(): Observable<any> {
    if (!this.localStorage.getOrganizacaoId()) this.router.navigate(["login"]);
    return this.vendedorLimit.asObservable().pipe(
      take(1),
      flatMap((res) => {
        if (res === null) {
          return this.vendedorService
            .getVendedorLimt(this.localStorage.getOrganizacaoId())
            .pipe(
              map((res) => {
                this.setVendedorLimit(res);
                return res;
              })
            );
        }
        return of(res);
      }),
      first()
    );
  }

  setVendedorLimit(data: any) {
    this.vendedorLimit.next(data);
  }

  getUserLimit(): Observable<any> {
    if (!this.localStorage.getOrganizacaoId()) this.router.navigate(["login"]);
    return this.userLimit.asObservable().pipe(
      take(1),
      flatMap((res) => {
        if (res === null) {
          return this.userService
            .getUserLimit(this.localStorage.getOrganizacaoId())
            .pipe(
              map((res) => {
                this.setUserLimit(res);
                return res;
              })
            );
        }
        return of(res);
      }),
      first()
    );
  }

  setUserLimit(data: any) {
    this.userLimit.next(data);
  }

  getCobrancaAssinatura() {
    return this.organizacaoService.getCobrancaAssinatura(
      this.localStorage.getOrganizacaoId()
    );
  }

  getOrganizacaoAssinatura(): Observable<any> {
    if (!this.localStorage.getOrganizacaoId()) this.router.navigate(["login"]);
    return this.organizacaoAssinatura.asObservable().pipe(
      take(1),
      flatMap((res) => {
        if (res === null) {
          return this.organizacaoService
            .getOrganizacaoAssinatura(this.localStorage.getOrganizacaoId())
            .pipe(
              map((res) => {
                this.setOrganizacaoAssinatura(res);
                return res;
              })
            );
        }
        return of(res);
      }),
      first()
    );
  }

  setOrganizacaoAssinatura(data: any) {
    this.organizacaoAssinatura.next(data);
  }

  clearCache() {
    this.setOrganizacaoData(null);
    this.setVendedorLimit(null);
    this.setOrganizacaoAssinatura(null);
  }

  getRefreshData(): Observable<any> {
    return this.refreshTokenData.asObservable().pipe(
      flatMap((res) => {
        if (res === null) {
          const user = this.localStorage.getUser();
          this.setRefreshData(user);
          return user;
        }
        return of(res);
      }),
      first()
    );
  }

  setRefreshData(newData: any) {
    this.refreshTokenData.next(newData);
  }

  getRouterGuardData(force: boolean = false): Observable<any> {
    if (!this.localStorage.getOrganizacaoId()) {
      this.loginService.logout();
      setTimeout(() => this.router.navigate(["login"]), 500);
    }
    
    return this.organizacaoData.asObservable().pipe(
      take(1),
      flatMap((res) => {
        if (
          res === null ||
          res.data.organizacao_id !== this.localStorage.getOrganizacaoId() ||
          force
        ) {
          return this.organizacaoService
            .getData(this.localStorage.getOrganizacaoId())
            .pipe(
              map((res) => {
                this.setOrganizacaoData(res);
                return res;
              })
            );
        }
        return of(res);
      }),
      first()
    );
  }

  setOrganizacaoData(newData: any) {
    this.organizacaoData.next(newData);
  }

  getTerminalLiberacaoData(params?: URLSearchParams) {
    if (!this.localStorage.getOrganizacaoId()) this.router.navigate(["login"]);
    return this.terminalLiberacaoData.asObservable().pipe(
      flatMap((res) => {
        if (res === null) {
          return this.organizacaoService
            .getTerminalLiberacao(this.localStorage.getOrganizacaoId())
            .pipe(
              map((res) => {
                this.setTerminalLiberacaoData(res);
                return res;
              })
            );
        }
        return of(res);
      }),
      first()
    );
  }

  setTerminalLiberacaoData(newData: any) {
    this.terminalLiberacaoData.next(newData);
  }
}
