import { Identifiable } from '../../../../shared/models/identifiable.model';
import { DetalheVendedor } from './detalhe-vendedor.model';

export class Vendedor implements Identifiable {
    id: string;
    colaborador: boolean;
    created_at: string;
    updated_at: string;
    email: string;
    externo_detalhes: any;
    externo_id: string;
    organizacao_id: string;
    plano_id: string;
    plano_tipo: string;
    telefone: string;
    tipo: string;
    vendedor_status_id: string;
    detalhe: DetalheVendedor;
    plano: any;
    vendedor_status: any;
    acordo_operacional:boolean;
    vendedor_id:string;
    conta_id:string;
    conta:object;
    especialidades_medicas:any;

    constructor(
        id?: string,
        colaborador?: boolean,
        created_at?: string,
        updated_at?: string,
        email?: string,
        externo_detalhes?: any,
        externo_id?: string,
        organizacao_id?: string,
        plano_id?: string,
        telefone?: string,
        tipo?: string,
        vendedor_status_id?: string,
        detalhe?:DetalheVendedor,
        plano?:any,
        vendedor_status?:any,
        acordo_operacional?:boolean,
        vendedor_id?:string,
        conta_id?:string,
        conta?:object,
        especialidades_medicas?:any,
    )
    {
        this.id = id;
        this.colaborador = colaborador;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.email = email;
        this.externo_detalhes = externo_detalhes;
        this.externo_id = externo_id;
        this.organizacao_id = organizacao_id;
        this.plano_id = plano_id;
        this.telefone = telefone;
        this.tipo = tipo;
        this.vendedor_status_id = vendedor_status_id;
        this.detalhe = detalhe;
        this.plano = plano;
        this.vendedor_status = vendedor_status;
        this.acordo_operacional = acordo_operacional;
        this.vendedor_id = vendedor_id;
        this.conta_id = conta_id;
        this.conta = conta;
        this.especialidades_medicas = especialidades_medicas;
    }
}
