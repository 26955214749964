import { IntercomService } from './../../../shared/services/intercom.service';
import { Injectable } from '@angular/core';
import { Response, Headers, URLSearchParams } from '@angular/http';
import { Subject } from 'rxjs';

import { environment } from '../../../../environments/environment'
import { LocalStorageService } from "./local-storage.service";
import { Oauth2TokenService } from "./oauth2-token.service";
import { APIRoutes } from '../../../shared/constants/api-routes.constant';
import { HttpInterceptor } from '../../../shared/interceptors/http-interceptor.service';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';

const USER_KEY = 'user';
const REFERRAL = 'referral';
const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const ORGANIZACAO_PLANO = 'organizacao_plano';
const BANNER = 'banner';
const CADASTRAR_AMBOS = 'cadastrar_ambos';
const COOKIE_CONSENT = 'cookieConsent';
const CONVITE_ANTECIPACAO_LOTE = 'convite-antecipacao-lote';
const LAST_REGISTERED_VENDOR = 'last_registered_vendor';

@Injectable()
export class AuthService {

    readonly userLocalStorage = this.localStorage.getObject(USER_KEY);
    public check: Boolean = false;
    private errorAlertValue: boolean;
    public errorAlert: Subject<boolean> = new Subject<boolean>();

    constructor(private oauth2Token: Oauth2TokenService,
        private http: HttpInterceptor,
        private localStorage: LocalStorageService,
        public intercomService: IntercomService
    ) {
        this.check = this.oauth2Token.token ? true : false;
    }

    change(value: boolean) {
        this.errorAlertValue = value;
        this.errorAlert.next(this.errorAlertValue);
    }

    listUnidade(params?: HttpParams) {
        let headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${this.oauth2Token.token}`);
        let url = environment.api.base_api + 'unidade';

        return this.http
            .get(url, { params: params, headers: headers })
            .pipe(
                map((res: any) => res)
            );
    }

    listContato(params?: HttpParams) {
        let headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${this.oauth2Token.token}`);
        let url = environment.api.base_api + 'unidade_contato';

        return this.http
            .get(url, { params: params, headers: headers })
            .pipe(
                map((res: any) => res)
            );
    }

    listConta(params?: HttpParams) {
        let headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${this.oauth2Token.token}`);
        let url = environment.api.base_api + 'unidade_conta';

        return this.http
            .get(url, { params: params, headers: headers })
            .pipe(
                map((res: any) => res)
            );
    }

    logout() {
        this.oauth2Token.token = null;
        this.check = false;
        this.localStorage.remove(USER_KEY);
        this.localStorage.remove(REFERRAL);
        this.localStorage.remove(ACCESS_TOKEN);
        this.localStorage.remove(REFRESH_TOKEN);
        this.localStorage.remove(ORGANIZACAO_PLANO);
        this.localStorage.remove(BANNER);
        this.localStorage.remove(CADASTRAR_AMBOS);
        this.localStorage.remove(COOKIE_CONSENT);
        this.localStorage.remove(CONVITE_ANTECIPACAO_LOTE);
        this.localStorage.remove(LAST_REGISTERED_VENDOR);

        this.intercomService.reset();
    }

}
