<div id="app-menu" class="main-menu" [class.collapsed]="menuCollapsed" [class.mobile]="isOpen">
  <div class="wrapper">
    <div class="upper-section">
      <button  class="btn-clean" (click)="collapseMenu(false)">
        <span class="material-icons">{{ menuCollapsed ? 'menu' : 'close'}}</span>
      </button>
      <div class="logo-container">
        <a class="logo">
          <img src="../../assets/img/iclinic.svg" >
        </a>
      </div>
      <div class="infos-container">
        <div class="avatar-mobile" [style.background-image]="avatarUrl"></div>
        <div class="infos">
          <p class="name">
            {{ loggedUser.name }}
          </p>
          <p class="organization">
            {{ (loggedUser.organizacoes[0].name.length>23)? (loggedUser.organizacoes[0].name | slice:0:23)+'...':(loggedUser.organizacoes[0].name) }}
          </p>
          <p class="role">
            {{ loggedUser.role_description }}
          </p>
        </div>
      </div>
    </div>
    <div class="avatar" [style.background-image]="avatarUrl"></div>
    <div class="middle-section">
      <!-- Um placeholder para cada item do menu -->
      <ng-container *ngFor="let item of this.showTheseMenuItens.keys()">
        <placeholder *ngIf="!hasMenuData"></placeholder>
      </ng-container>

      <!-- Items do menu em forma de componente ('./items') -->
      <particular-menu-item (onClickSelect)="onClickSelect($event)" (onClickItem)="onClickItem()" *ngIf="hasMenuData && showTheseMenuItens.get('particular')"></particular-menu-item>
      <plano-de-saude-menu-item (onClickSelect)="onClickSelect($event)" (onClickItem)="onClickItem()" *ngIf="hasMenuData && showTheseMenuItens.get('plano-de-saude')"></plano-de-saude-menu-item>
      <antecipacoes-menu-item  (onClickSelect)="onClickSelect($event)" (onClickItem)="onClickItem()" *ngIf="hasMenuData && showTheseMenuItens.get('antecipacoes')"></antecipacoes-menu-item>
      <gestao-financeira-menu-item (onClickSelect)="onClickSelect($event)" (onClickItem)="onClickItem()" *ngIf="hasMenuData && (showTheseMenuItens.get('gestao-financeira') || referral === 'iclinic')"></gestao-financeira-menu-item>
      <simulador-de-vendas-menu-item *ngIf="hasMenuData && showTheseMenuItens.get('simulador-de-vendas')"></simulador-de-vendas-menu-item>
      <config-menu-item  (onClickSelect)="onClickSelect($event)" (onClickItem)="onClickItem()" *ngIf="hasMenuData && (referral !== 'iclinic' || (referral === 'iclinic' && role === 'admin'))"></config-menu-item>
    </div>
    <div class="referral" *ngIf="hasMenuData">
      <indique-um-amigo-menu-item></indique-um-amigo-menu-item>
    </div>
    <div class="logout">
      <a class="item-link" (click)="logout()">
        <span class="material-icons">exit_to_app</span>
        <span class="title">Sair</span>
      </a>
    </div>
    <div *ngIf="isOpen" class="item-menu-open slide-in">
      <div class="title-menu-open" (click)="toggleItemChildren()">
        <span class="material-icons">keyboard_arrow_left</span>
        <h3>{{ title }}</h3>
      </div>
      <ul class="children-container">
        <li *ngFor="let child of children" routerLinkActive="active-child">
          <a [routerLink]="[child.route]" (click)="menuItemClicked()">
            <span>{{ child.title }}</span>
          </a>
        </li>
      </ul>
      <div class="indique-mobile" *ngIf="title === 'Configurações'">
        <a [routerLink]="['/pages/referral']" (click)="menuItemClicked()" routerLinkActive="active-child">
          <span class="material-icons">favorite</span>
          <h3>Indique um amigo</h3>
        </a>
      </div>
    </div>
  </div>
</div>
