import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class RoutePermissionGuard implements CanActivate {

    constructor(
        private localStorage: LocalStorageService,
        ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.routeConfig.path === "gestao-financeira" && !this.getPermissions().includes('dashboard')) {
            return false;
        } else if (route.routeConfig.path === "antecipacao-fatura" && !this.getPermissions().includes('emprestimo_incluir')) {
            return false;
        } else if (state.url === "/pages/dados-cadastrais/unidade/create" && !this.getPermissions().includes('vendedor_alterar')) {
            return false;
        } else if (route.routeConfig.path === "conciliacao" && !this.getPermissions().includes('conciliacao_totais')) {
            return false;
        } else {
            return true;
        }
    }

    getPermissions() {
        return this.localStorage.getUserPermissions();
    }

}
