import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
	selector: 'not-found',
	templateUrl: './templates/base.html',
	styleUrls: ['./scss/base.scss'],
})

export class NotFoundComponent implements OnInit {

	constructor(
        private location: Location
	) {
	}

	ngOnInit() {
    }
    
    goBack() {
        this.location.back();
    }

}