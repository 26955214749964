import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { MatomoInjector, MatomoTracker } from 'ngx-matomo';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { LocalStorageService } from './pages/user/services/local-storage.service';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { IntercomService } from './shared/services/intercom.service';
import { MicrosoftClarity } from './shared/services/microsoft-clarity.service';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'app';
  subscription: Subscription;

  constructor(
      private matomoInjector: MatomoInjector,
      private matomoTracker: MatomoTracker,
      private router: Router,
      private localStorage: LocalStorageService,
      private config: NgSelectConfig,
      public intercomService: IntercomService,
      public microsoftClarityService: MicrosoftClarity,
      public googleAnalyticsService: GoogleAnalyticsService
    ) {

    const user: any = this.localStorage.getUser() || {};
    if (environment.name === 'prod' && user.role_name !== 'admin') {
      this.matomoInjector.init('//matomo.medicinaesolutions.com/', 4);
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        const currentUrl = location.href;
        this.matomoTracker.setReferrerUrl(currentUrl);
        this.matomoTracker.setCustomUrl(currentUrl);
        this.matomoTracker.setDocumentTitle('Page');
        this.matomoTracker.trackPageView();

        this.googleAnalyticsService.trackPageView(event.urlAfterRedirects);
        this.googleAnalyticsService.trackPageViewByUser(event.urlAfterRedirects, this.localStorage.getObject('user'));
      });
    }

    this.config.notFoundText = 'Nenhum registro encontrado.';
    this.config.clearAllText = 'Limpar';

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  ngOnInit() {
    if (environment.featureFlags.maintenancePage) {
      this.router.navigate(["/maintenance"]);
    }
    
    this.intercomService.init();
    this.microsoftClarityService.init();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
