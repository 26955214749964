import { Injectable } from '@angular/core';
import { Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIRoutes } from '../../../../shared/constants/api-routes.constant';
import { LocalStorageService } from '../../../user/services/local-storage.service';
import { environment } from '../../../../../environments/environment';
import { HttpInterceptor } from '../../../../shared/interceptors/http-interceptor.service';
import { AuthenticatedWebService } from '../../../../shared/services/authenticated.web.service';
import { OAuthService } from '../../../user/services/oauth.service';
import { convertDateToUTC } from '../../../../shared/utils/utils';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class LoteService extends AuthenticatedWebService{

    constructor(
        private http:HttpInterceptor,
        oauthService:OAuthService,
        private localStorage:LocalStorageService
    ) {
        super(oauthService);
    }

    getDados():Observable<Array<any>>{
        let url = environment.api.base_api + APIRoutes.LOTE;
        let params = new HttpParams()
          .set("organizacao_id", this.localStorage.getOrganizacaoId())
          .set("orderBy", "data")
          .set("sortedBy", "desc");

        return this.http
            .get(url, {params: params, headers: this.httpHeaders })
            .pipe(
                map((res: any) => res.data),
                map((res) => res.map((lote) => {
                    lote.created_at && lote.created_at.date ?convertDateToUTC(new Date(lote.created_at.date.replace(' ', 'T'))) : convertDateToUTC(new Date(lote.created_at.replace(' ', 'T')));
                    return lote;
                }))
            );
    }

    getDadosParticulares(params:HttpParams):Observable<Array<any>>{
        let url = environment.api.base_api + APIRoutes.LOTE;

        return this.http
            .get(url, {params: params, headers: this.httpHeaders })
            .pipe(
                map((res: any) => res.data)
            );
    }

    getCustomData(params: HttpParams):Observable<Array<any>>{
        let url = environment.api.base_api + APIRoutes.LOTE;

        return this.http
            .get(url, {params: params, headers: this.httpHeaders })
            .pipe(
                map((res: any) => res.data)
            );
    }
}
