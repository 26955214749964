export function formatCurrency(value: any): any {
  let formatter = new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2});
  return formatter.format(value);
}

export function formatCurrencyWithoutSymbol(value: any): any {
  let formatter = new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2});
  let x = formatter.format(value);
  return x.replace('R$', '');
}

export function formatDate(date: Date): any {
  if (date) {
    // Correção para o erro Unsupported time zone specified undefined
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric', month: 'numeric', year: 'numeric', timeZone: 'UTC'
    };
    let formatter = new Intl.DateTimeFormat('pt-BR', options);
    if (formatter.resolvedOptions().timeZone.toLowerCase() === 'etc/unknown') {
      options.timeZone = 'UTC';
      formatter = new Intl.DateTimeFormat('en-US', options);
    }
    return formatter.format(new Date(date));
  }
}

export function formatDateDayAndMonth(date: Date): any {
  if (date) {
    // Correção para o erro Unsupported time zone specified undefined
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric', month: 'numeric', timeZone: 'UTC'
    };
    let formatter = new Intl.DateTimeFormat('pt-BR', options);
    if (formatter.resolvedOptions().timeZone.toLowerCase() === 'etc/unknown') {
      options.timeZone = 'UTC';
      formatter = new Intl.DateTimeFormat('en-US', options);
    }
    return formatter.format(new Date(date));
  }
}

export function formatDateWithHour(date: Date): any {
  if (date) {
    // Correção para o erro Unsupported time zone specified undefined
    const options: Intl.DateTimeFormatOptions = {
      minute: 'numeric', hour: 'numeric', day: 'numeric', month: 'numeric', year: 'numeric'
    };
    let formatter = new Intl.DateTimeFormat('pt-BR', options);
    if (formatter.resolvedOptions().timeZone.toLowerCase() === 'etc/unknown') {
      options.timeZone = 'UTC';
      formatter = new Intl.DateTimeFormat('en-US', options);
    }
    return formatter.format(new Date(date));
  }
}

export function formatHourFromDate(date: Date, separator = 'h', seconds = false): any {
  if (date) {
    // Correção para o erro Unsupported time zone specified undefined
    const options: Intl.DateTimeFormatOptions = {
      minute: 'numeric', hour: 'numeric', day: 'numeric', month: 'numeric', year: 'numeric', timeZone: 'UTC'
    };
    let formatter = new Intl.DateTimeFormat('pt-BR', options);
    if (formatter.resolvedOptions().timeZone.toLowerCase() === 'etc/unknown') {
      options.timeZone = 'UTC';
      formatter = new Intl.DateTimeFormat('en-US', options);
    }

    // let formatted = formatter.format(new Date(date));
    let formatted = formatter.format(new Date(date));
    let h = new Date(date).getHours();
    let m = new Date(date).getMinutes();
    let s = new Date(date).getSeconds();
    if (seconds) {
      return ('0' + h).slice(-2) + separator + ('0' + m).slice(-2) + separator + ('0' + s).slice(-2);
    } else {
      return ('0' + h).slice(-2) + separator + ('0' + m).slice(-2);
    }
  }
}

export function getMonthName(date: any) {
  if (date) {
    const options: Intl.DateTimeFormatOptions = {
      month: 'long', timeZone: 'UTC'
    };
    let formatter = new Intl.DateTimeFormat('pt-BR', options);
    if (formatter.resolvedOptions().timeZone.toLowerCase() === 'etc/unknown') {
      options.timeZone = 'UTC';
      formatter = new Intl.DateTimeFormat('en-US', options);
    }
    return formatter.format(new Date(date));
  }
}

export function formatDateToSend(date: Date): any {
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
}

export function formatDateByHour(date: any): any {
  const newDate = new Date(date);
  return `${('0' + newDate.getHours()).slice(-2)}:${('0' + newDate.getMinutes()).slice(-2)}`;
}

export function formatDateToEdit(date: Date): any {
  return ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
}

export function roundNumber(value: number): any {
  let formatter = new Intl.NumberFormat('pt-BR', {style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
  return formatter.format(value);
}

export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
    date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()
  );
}

export function orderByHighestValue(array: any, key: any) {
  let filtered = array.sort((a, b) => {
    return b[key] - a[key];
  });
  return filtered;
}

export function orderByDescendingText(array, key) {
  let filtered = array.sort((a, b) => {
    if (a.descricao > b.descricao) {
      return -1;
    }
    if (a.descricao < b.descricao) {
      return 1;
    }
    return 0;
  });

  return filtered;
}

export function orderByHighestValueOfDividedKeys(array: any, key1: any, key2: any) {
  return array.sort((a, b) => {
    return (b[key2] ? b[key1] / b[key2] : b[key1]) - (a[key2] ? a[key1] / a[key2] : a[key1]);
  });
}

export function orderByLowestValue(array: any, key: any) {
  let filtered = array.sort((a, b) => {
    return a[key] - b[key];
  });
  return filtered;
}

export function sumObjectsOfArray(array: any, key: any) {
  let result = 0;
  array.forEach((element) => {
    result = result + element[key];
  });
  return result;
}

export function sumOfDividedKeysOfArrayElement(array: any, key1: any, key2: any) {
  let result = 0;
  array.forEach((element) => {
    if (element[key2] === 0) {
      result = result + (element[key1] / 1);
    } else {
      result = result + (element[key1] / element[key2]);
    }
  });
  return result;
}

export function averageValue(array: any, key: any) {
  let result = 0;
  array.forEach((element) => {
    result = result + element[key];
  });
  return result / array.length;
}

export function averageValueOfDividedKeys(array: any, key1: any, key2: any) {
  let result = 0;
  array.forEach((element) => {
    if (element[key2] === 0) {
      result = result + (element[key1] / 1);
    } else {
      result = result + (element[key1] / element[key2]);
    }
  });
  return result / array.length;
}

export function parseDateObject(date: string) {
  if (date) {
    const splitDate = date.split('-');
    const dDate = Number(splitDate[2][1] + splitDate[2][2]);
    const mDate = Number(splitDate[1]);
    const yDate = Number(splitDate[0]);
    let data = convertDateToUTC(new Date(yDate, mDate - 1, dDate));
    let parsedDate = formatDateToSend(data);
    return parsedDate;
  }
}

export function parseDateObjectWithFirstDay(date: string) {
  if (date) {
    const splitDate = date.split('-');
    const mDate = Number(splitDate[1]);
    const yDate = Number(splitDate[0]);
    let data = convertDateToUTC(new Date(yDate, mDate - 1, 1));
    let parsedDate = formatDateToSend(data);
    return parsedDate;
  }
}

export function parseDateToEdit(date: string) {
  if (date) {
    const splitDate = date.split('-');
    const dDate = `${splitDate[2][0]}${splitDate[2][1]}`;
    const mDate = splitDate[1];
    const yDate = splitDate[0];
    let parsedDate = `${dDate}-${mDate}-${yDate}`;
    return parsedDate;
  }
}

export function formatCpfCnpj(value) {
  if (value.length > 11) {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
  } else {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
  }
}


export function stringFormat(template: string, ...args: any[]) {
  return template.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined'
          ? args[number]
          : match
          ;
  });
};
