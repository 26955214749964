import { Injectable } from '@angular/core';
import { RefreshLoginGuard } from './refresh-login-guard.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { flatMap } from 'rxjs/operators';
import { UpdateUserGuard } from './update-user-guard.service';

@Injectable()

export class CombinedGuards implements CanActivate {
    
    constructor(private gA: RefreshLoginGuard, private gC: UpdateUserGuard) {}
  
    canActivate(r: ActivatedRouteSnapshot, s: RouterStateSnapshot) {
          return this.gA.canActivate(r, s).pipe(flatMap((res) => this.gC.canActivate(r, s)));
    }
  }