<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <h1>
        Opa!
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <span style="color: #0063CF">
        <i class="fa fa-exclamation-triangle fa-4x"></i>
      </span>
    </div>
  </div>
  <p class="fs27 mb15">
    Ocorreu um problema no seu pagamento.
  </p>
  <p>
    Não conseguimos identificar o seu pagamento
  </p>
  <p class="gotham-bold">
    Atualize seus dados para continuar usurfruindo de todos os beneficios da iClinic Pay.
  </p>
  <button
  type="button"
  class="assinar-btn gotham-bold"
  [routerLink]="['/pagamento-assinatura']"
  >
    Atualizar dados
  </button>
  <button
  type="button"
  class="logout-btn gotham-bold darkblue-gradient-bg"
  (click)="logout()"
  >
    Voltar
  </button>
</div>
