import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class DetectDeviceService {

  checkDevice() {
    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      return "mobile";
    else if (/Chrome/i.test(ua))
      return "chrome";
    else
      return "desktop"
  }

}