import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectionBackend, Http, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { RefreshTokenRequest } from '../../pages/user/models/user.requests';
import { ThemeSharedService } from '../../theme/services/theme.shared.service';
import { APIRoutes } from '../constants/api-routes.constant';
import { IntercomService } from '../services/intercom.service';

@Injectable()
export class HttpInterceptor extends Http {

    urlRedirect;

    private readonly ACCESS_TOKEN = 'access_token';
    private readonly REFRESH_TOKEN = 'refresh_token';
    private awaitRequest = false;
    private countErrors = 0;

    constructor(
        backend: ConnectionBackend,
        defaultOptions: RequestOptions,
        private routerExtensions: Router,
        private themeService: ThemeSharedService,
        private httpClient: HttpClient,
        private intercomService: IntercomService
    ) {
        super(backend, defaultOptions);

        const referral = localStorage.getItem('referral');
        const accessToken = localStorage.getItem('access_token');

        if (accessToken && !referral) {
            this.logout();
            this.routerExtensions.navigate(['/login']);
        }

    }

    private getOrganizacaoId() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (!user) return;
        return user.organizacoes[0].id;
    }

    get(url: string, options?: any): Observable<any> {
        return this.httpClient.get(url, options).pipe(
          catchError((err, caught) => this.onCatch(err, caught)));
    }

    post(url: string, body: any, options?: any): Observable<any> {
        return this.httpClient.post(url, body, options).pipe(
          catchError((err, caught) => this.onCatch(err, caught)));
    }

    patch(url: string, body: any, options?: any): Observable<any> {
        return this.httpClient.patch(url, body, options).pipe(
          catchError((err, caught) => this.onCatch(err, caught)));
    }

    put(url: string, body: any, options?: any): Observable<any> {
        return this.httpClient.put(url, body, options).pipe(
          catchError((err, caught) => this.onCatch(err, caught)));
    }

    delete(url: string, options?: any): Observable<any> {
        const organizacaoId = this.getOrganizacaoId();
        url = organizacaoId? url + `?organizacao_id=${organizacaoId}` : url;

        return this.httpClient.delete(url, options).pipe(
          catchError((err, caught) => this.onCatch(err, caught)));
    }

    private onCatch(error: any, caught: Observable<any>): Observable<any> {
        let status = error.status;
        let message = 'Erro genérico';
        const body = error.error;
        if (typeof body?.messages !== 'undefined' && body?.messages !== null) {
            message = body.messages[0];
        }

        if (+status === 401) {
            const url = window.location.href.split('#');
            const newUrl = url[1].substr(1, 8);
            if (!this.awaitRequest) {
                this.awaitRequest = true;
                this.authByRefreshToken().subscribe((res) => {
                    if (newUrl === 'auth/sso') {
                        setTimeout(() => {
                            window.location.href = this.urlRedirect;
                            location.reload();
                        }, 1000);
                    }
                    else {
                        location.reload();
                    }
                    this.awaitRequest = false;
                    this.countErrors = 0;
                    return;
                }, (err) => {
                    const checkIfRouteIsChangePassword = url[1].substr(1);
                    if (checkIfRouteIsChangePassword !== 'change-password') {
                        const refreshTokenInvalid = this.checkRefreshTokenInvalid(err);
                        if (this.countErrors > 1 || refreshTokenInvalid) {
                            this.logout();
                            window.location.reload();
                        }
                        this.countErrors++;
                        this.routerExtensions.navigate(['/login']);
                        this.awaitRequest = false;
                        return;
                    }
                });
            }
        } else if ((+status >= 400) && (+status < 500)) {
          const url = window.location.href.split('#');
          const newUrl = url[1].substr(1);
          if (newUrl !== "pages/planos-de-saude/meus-convenios/list") {
            return observableThrowError(error);
          }
        } else {
            if (typeof this.themeService !== 'undefined') {
                this.themeService.setGenericErrorHandler(true);
            }
        }

        return observableThrowError(error);
    }

    getRefreshToken(refreshToken: string): Observable<any> {
        let data: RefreshTokenRequest = new RefreshTokenRequest(refreshToken);
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.httpClient
            .post(environment.api.base_url + APIRoutes.OAUTH, data, { headers })
            .pipe(
                map((res: any) => res)
            );
    }

    refreshLogin(refreshToken: string): Observable<any> {
        return this.getRefreshToken(refreshToken)
            .pipe(
                map((res) => this.setTokens(res))
            );
    }

    setTokens(response: any) {
        this.setTokensOnLocalStorage(response.access_token, response.refresh_token);
        return response;
    }

    setTokensOnLocalStorage(access_token: string, refresh_token: string) {
        window.localStorage[this.ACCESS_TOKEN] = access_token;
        window.localStorage[this.REFRESH_TOKEN] = refresh_token;

        this.setRedirectUrl(access_token, refresh_token);
    }

    setRedirectUrl(access_token: string, refresh_token: string) {
        const redirect = window.localStorage['urlToRedirect']
        const goBack = window.localStorage['goBack']

        const baseUrl = window.location.href.split('#')[0];
        this.urlRedirect = `${baseUrl}#/auth/sso?access_token=${access_token}&refresh_token=${refresh_token}&redirect=${redirect}&go_back=${goBack}`
    }

    authByRefreshToken(): Observable<any> {
        let tokens = this.getTokens();
        return this.refreshLogin(tokens.refreshToken);
    }

    getTokens(): { accessToken, refreshToken } {
        return {
            accessToken: window.localStorage[this.ACCESS_TOKEN],
            refreshToken: window.localStorage[this.REFRESH_TOKEN]
        };
    }

    logout() {
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('referral');
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('organizacao_plano');
        window.localStorage.removeItem('banner');
        window.localStorage.removeItem('cadastrar_ambos');
        window.localStorage.removeItem('cookieConsent');
        window.localStorage.removeItem('convite-antecipacao-lote');
        window.localStorage.removeItem('last_registered_vendor');

        this.intercomService.reset();
    }

    checkRefreshTokenInvalid(err) {
        if (err && err.error) {
            return err.error.developer_details[0].includes('The refresh token is invalid.');
        }

        return false;
    }
}
