import { Pipe, PipeTransform } from "@angular/core";
import { ColumnSetting, Types } from "../components/refactor-components/ui/icp-table/icp-table.model";
import { formatCurrency, formatDate } from "../utils/formatter";

@Pipe({ name: 'valuebycolumnname' })
export class ValueByColumnNamePipe implements PipeTransform {
  transform(data: any, column: ColumnSetting) {
    if (data && column) {
      return this.getValueByColumnName(data, column);
    }
    else {
      return '';
    }
  }

  getValueByColumnName(data: any, column: ColumnSetting) {
    let split = [];
    let result: any;
    
    if (column.dataProperty.indexOf('.') > -1) {
      split = column.dataProperty.split('.');

      let dataChild = data;
      for (let prop of split) {
        dataChild = dataChild[prop];
      }

      result = dataChild
    }
    else {
      result = data[column.dataProperty];
    }

    if (column.type === Types.Date) {
      result = formatDate(result);
    }
    else if (column.type === Types.Currency) {
      result = formatCurrency(result);
    }

    return result;
  }
}