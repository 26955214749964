import { OrganizacaoRoutes } from './organizacao.routes.constants';
import { AntecipacaoRoutes } from './antecipacao.routes.constants';
import { DashboardRoutes } from './dashboard.routes.constants';

export class PagesRoutes {

    public readonly BASE:string;

    public readonly ORGANIZACAO:OrganizacaoRoutes;
    public readonly ANTECIPACAO:AntecipacaoRoutes;
    public readonly DASHBOARD:DashboardRoutes;

    constructor(route:string, organizacaoRoutes:OrganizacaoRoutes, antecipacaoRoutes:AntecipacaoRoutes, dashboardRoutes:DashboardRoutes){
        this.BASE = route;
        this.ORGANIZACAO = organizacaoRoutes;
        this.ORGANIZACAO.preAppend(this.BASE);
        this.ANTECIPACAO = antecipacaoRoutes;
        this.ANTECIPACAO.preAppend(this.BASE);
        this.DASHBOARD = dashboardRoutes;
        this.DASHBOARD.preAppend(this.BASE);        
    }

}