<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="app-breadcrumb">
                <p>
                    <span>
                        Você está em: 
                        <b>
                            {{ (father !== son) ? father : ' '}}
                            {{ (father && son && father !== son) ? ' - ' : '' }}
                            {{ son }}
                        </b>
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>