import { BaseRoutes } from './base.routes.constants';


export class DashboardRoutes extends BaseRoutes{

    constructor(route:string){
        super(route);
    }

    preAppend(route:string){
        super.preAppend(route);
    }
}