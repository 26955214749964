import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'placeholder',
  template: `<div class="placeholder"></div>`,
  styles: [`
    .placeholder {
      background: linear-gradient(-90deg, #d8dde2, rgba(29, 54, 87, 0.15), #d8dde2);
      animation: gradientBG 2s ease infinite;
      width: 100%;
      height: calc(44px - 8px);
      background-size: 400% 400%;
      margin: 8px 0;
    }

    @keyframes gradientBG {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }    
  `]
})

export class PlaceholderComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}