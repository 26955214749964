<style>
  .center-me {
    margin: 0 auto;
  }
  .inline-block-center {
    text-align: center;
  }
</style>
<div class="generic-error-container" id="generic-error-container">
  <h3 class="gotham-bold mb25">
    Ops!
  </h3>
  <i class="fas fa-exclamation-triangle fa-5x mb25" style="color: #0063CF"></i>
  <p class="mb25">
    Desculpe, identificamos um erro e <br/>
    estamos trabalhando para consertá-lo.
  </p>
    <div class="inner-container mb25">
      <div class="center-me inline-block-center">
        <div class="spinner-border text-primary" role="status" (load)="redirect()">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="gotham-medium mt25">Você será redirecionado em instantes</p>
      </div>
    </div>
</div>
