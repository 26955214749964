import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErroAssinatura } from './assinatura/components/erro-assinatura.component';
import { Pages } from './pages.component';
import { InviteQueueComponent } from './referral/components/invite-queue.component';
import { RefreshLoginGuard } from './user/services/refresh-login-guard.service';
import { RoutePermissionGuard } from './user/services/route-permission-guard.service';
import { UpdateUserGuard } from './user/services/update-user-guard.service';

import { OnboardingRouterGuard } from '../onboarding/router-guards/onboarding-router-guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('app/pages/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('app/onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: 'pages',
    component: Pages,
    children: [
      { path: 'dados-cadastrais', canActivate: [RoutePermissionGuard], loadChildren: () => import('app/pages/dados-cadastrais/dados-cadastrais.module').then(m => m.CadastroOrganizacaoModule)},
      { path: 'sincronizacao', canActivate: [OnboardingRouterGuard, RefreshLoginGuard, UpdateUserGuard], loadChildren: () => import('app/pages/sincronizacao/sincronizacao.module').then(m => m.SincronizacaoModule)},
      { path: 'historico-antecipacao', canActivate: [OnboardingRouterGuard, RefreshLoginGuard, UpdateUserGuard], loadChildren: () => import('app/pages/historico-antecipacao/historico-antecipacao.module').then(m => m.HistoricoAntecipacaoModule)},
      { path: 'simulador-vendas', canActivate: [RefreshLoginGuard], loadChildren: () => import('app/pages/simulador-vendas/simulador-vendas.module').then(m => m.SimuladorVendasModule)},
      { path: 'permissionamento', canActivate: [OnboardingRouterGuard, RefreshLoginGuard, UpdateUserGuard], loadChildren: () => import('app/pages/permissionamento/permissionamento.module').then(m => m.PermissionamentoModule)},
      { path: 'referral', canActivate: [RefreshLoginGuard], loadChildren: () => import('app/pages/referral/referral.module').then(m => m.ReferralModule)},
      { path: 'assinatura', canActivate: [OnboardingRouterGuard, RefreshLoginGuard, UpdateUserGuard], loadChildren: () => import('app/pages/assinatura/assinatura.module').then(m => m.AssinaturaModule)},
      { path: 'particular', canActivate: [OnboardingRouterGuard, RefreshLoginGuard, UpdateUserGuard], loadChildren: () => import('app/pages/medicinae-pay/medicinae-pay.module').then(m => m.MedicinaePayModule)},
      { path: 'gestao-financeira', canActivate: [ OnboardingRouterGuard, RefreshLoginGuard, UpdateUserGuard, RoutePermissionGuard], loadChildren: () => import('app/pages/gestao-financeira/gestao-financeira.module').then(m => m.GestaoFinanceiraModule)},
      { path: 'planos-de-saude', canActivate: [OnboardingRouterGuard], loadChildren: () => import('app/pages/convenios/convenios.module').then(m => m.ConveniosModule)},
      { path: 'antecipacoes', canActivate: [OnboardingRouterGuard, RefreshLoginGuard, UpdateUserGuard], loadChildren: () => import('app/pages/antecipacoes/antecipacoes.module').then(m => m.AntecipacoesModule)},
      // { path: 'retrospectiva', loadChildren: () => import('app/pages/retrospective/retrospective.module').then(m => m.RetrospectiveModule)},
      { path: 'erro-assinatura', component: ErroAssinatura},
    ]
  },
  {
    path: 'invite-queue',
    component: InviteQueueComponent
  },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
