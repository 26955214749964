import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export const routes: Routes = [
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: 'pages/dados-cadastrais/plano/list', redirectTo:'pages/planos-de-saude/meus-convenios/list', pathMatch: 'full' },
  { path: 'maintenance', component: MaintenanceComponent, pathMatch: 'full'},
  { path: '**', redirectTo: 'not-found' },
  { path: 'not-found', component: NotFoundComponent},
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' });
