import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safe-html.pipe';
import { OptionDisplayPipe } from './select-option.pipe';
import { ValueByColumnNamePipe } from './value-by-column-name.pipe';

@NgModule({
  declarations: [SafeHtmlPipe, OptionDisplayPipe, ValueByColumnNamePipe],
  imports: [ CommonModule ],
  exports: [SafeHtmlPipe, OptionDisplayPipe, ValueByColumnNamePipe],
  providers: [SafeHtmlPipe, OptionDisplayPipe, ValueByColumnNamePipe],
})
export class PipeModule {}