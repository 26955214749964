import { Identifiable } from '../../../shared/models/identifiable.model';
import { Organizacao } from './organizacao.model';

export class User implements Identifiable{
    id:string;
    name:string;
    email:string;
    password:string;
    password_confirmation:string;
    organizacoes:Array<Organizacao>;
    data_confirmacao_email:string;
    permissoes: Array<string>;
    role_id:string;
    profile_description:any;
    profile_color:any;
    photo_id:string;
    profile_photo:any;
    profile_photo_url:any;
    role_name: string;
    role_level: number;
    role_description: string;
    role_color: string;

    constructor(
        id?:string, 
        name?:string, 
        email?:string, 
        password?:string, 
        password_confirmation?:string, 
        organizacoes?:Array<Organizacao>, 
        data_confirmacao_email?:string,
        permissoes?: Array<string>,
        role_id?:string,
        profile_description?:any,
        profile_color?:any,
        photo_id?:string,
        profile_photo?:any,
        profile_photo_url?:any,
        role_name?:string,
        role_level?: number,
        role_description?: string,
        role_color?: string
    )
    {
        this.id = id;
        this.name = name;
        this.email = email;
        this.password = password;
        this.password_confirmation = password_confirmation;
        this.organizacoes = organizacoes;
        this.data_confirmacao_email = data_confirmacao_email;
        this.permissoes = permissoes;
        this.role_id = role_id;
        this.profile_description = profile_description;
        this.profile_color = profile_color;
        this.photo_id = photo_id;
        this.profile_photo = profile_photo;
        this.profile_photo_url = profile_photo_url;
        this.role_name = role_name;
        this.role_level = role_level;
        this.role_description = role_description;
        this.role_color = role_color;
    }
}