export const MENU_ITEMS = [
    {
        title: 'iClinic Pay',
        path: '/pages/dados-cadastrais/particular/list',
        icon: `<?xml version="1.0" encoding="utf-8"?><!-- Generator:Adobe Illustrator 23.0.3,SVG Export Plug-In . SVG Version:6 Build 0) --><svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve"><style type="text/css">.svg-container .st0{fill:#0063CF}.svg-container .st1{fill:#FF9E1B}.svg-container .st2{fill:#5B7F95}.svg-container .st3{fill:#1B365D}.svg-container .st4{fill:#00335E}</style><g><g><rect x="81" y="68.1" class="st0" width="42.6" height="5.6"/></g><g><path class="st1" d="M120.3,92.4h-43V35h5.6v51.7h37.4c0.5,0,1-0.2,1.4-0.5l3.7,4.2C124,91.7,122.2,92.4,120.3,92.4z"/></g><g><path class="st0" d="M125.4,90.5l-3.7-4.2c0.5-0.4,0.7-1,0.7-1.6V40c0-1.2-1-2.2-2.2-2.2H80v-5.6h40.2c4.3,0,7.8,3.5,7.8,7.8v44.6
      C128,86.9,127,89,125.4,90.5z"/></g><g><rect x="104.6" y="43.2" class="st1" width="10.9" height="5.6"/></g><g><rect x="94.9" y="43.2" class="st1" width="6.7" height="5.6"/></g><g><path class="st2" d="M76.6,123.2H6.2c-3.4,0-6.2-2.8-6.2-6.2V11.1c0-1.4,0.5-2.8,1.4-3.9l4.4,3.5L5.6,116.9c0,0.3,0.3,0.6,0.6,0.6
      l70.8,0l4,3.8C79.9,122.5,78.3,123.2,76.6,123.2z"/></g><g><path class="st3" d="M81,121.3l-4-3.8l0.2-106.4c0-0.3-0.3-0.6-0.6-0.6L5.8,10.7L1.4,7.2c1.2-1.5,3-2.4,4.9-2.4h70.4
      c3.4,0,6.2,2.8,6.2,6.2v105.8C82.9,118.6,82.2,120.1,81,121.3z"/></g><g><path class="st2" d="M65.6,57H17.1c-2.9,0-5.3-2.4-5.3-5.3V21.9c0-1.4,0.5-2.7,1.5-3.7l4,4l0.1,29.6l48.2-0.4l3.7,4.1
      C68.3,56.5,67,57,65.6,57z"/></g><g><path class="st3" d="M69.3,55.5l-4-4l-0.1-29.6l-48.2,0.4l-3.7-4.1c1-1,2.3-1.5,3.7-1.5h48.5c2.9,0,5.3,2.4,5.3,5.3v29.8
      C70.9,53.2,70.3,54.5,69.3,55.5z"/></g><g><rect x="16.7" y="69.4" class="st3" width="12.3" height="5.6"/></g><g><rect x="35.3" y="69.4" class="st3" width="12.3" height="5.6"/></g><g><rect x="53.6" y="69.4" class="st3" width="12.3" height="5.6"/></g><g><rect x="16.8" y="84.8" class="st3" width="12.3" height="5.6"/></g><g><rect x="35.4" y="84.8" class="st3" width="12.3" height="5.6"/></g><g><rect x="53.7" y="84.8" class="st3" width="12.3" height="5.6"/></g><g><rect x="16.7" y="100.1" class="st3" width="12.3" height="5.6"/></g><g><rect x="35.3" y="100.1" class="st3" width="12.3" height="5.6"/></g><g><rect x="53.6" y="100.1" class="st3" width="12.3" height="5.6"/></g></g></svg>`,
        level: 2000,
        inactive: false,
        disabled: true,
    },
    {
        title: 'iClinic Pay',
        path: '',
        icon: `<?xml version="1.0" encoding="utf-8"?><!-- Generator:Adobe Illustrator 23.0.3,SVG Export Plug-In . SVG Version:6 Build 0) --><svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve"><style type="text/css">.svg-container .st0{fill:#0063CF}.svg-container .st1{fill:#FF9E1B}.svg-container .st2{fill:#5B7F95}.svg-container .st3{fill:#1B365D}.svg-container .st4{fill:#00335E}</style><g><g><rect x="81" y="68.1" class="st0" width="42.6" height="5.6"/></g><g><path class="st1" d="M120.3,92.4h-43V35h5.6v51.7h37.4c0.5,0,1-0.2,1.4-0.5l3.7,4.2C124,91.7,122.2,92.4,120.3,92.4z"/></g><g><path class="st0" d="M125.4,90.5l-3.7-4.2c0.5-0.4,0.7-1,0.7-1.6V40c0-1.2-1-2.2-2.2-2.2H80v-5.6h40.2c4.3,0,7.8,3.5,7.8,7.8v44.6
        C128,86.9,127,89,125.4,90.5z"/></g><g><rect x="104.6" y="43.2" class="st1" width="10.9" height="5.6"/></g><g><rect x="94.9" y="43.2" class="st1" width="6.7" height="5.6"/></g><g><path class="st2" d="M76.6,123.2H6.2c-3.4,0-6.2-2.8-6.2-6.2V11.1c0-1.4,0.5-2.8,1.4-3.9l4.4,3.5L5.6,116.9c0,0.3,0.3,0.6,0.6,0.6
        l70.8,0l4,3.8C79.9,122.5,78.3,123.2,76.6,123.2z"/></g><g><path class="st3" d="M81,121.3l-4-3.8l0.2-106.4c0-0.3-0.3-0.6-0.6-0.6L5.8,10.7L1.4,7.2c1.2-1.5,3-2.4,4.9-2.4h70.4
        c3.4,0,6.2,2.8,6.2,6.2v105.8C82.9,118.6,82.2,120.1,81,121.3z"/></g><g><path class="st2" d="M65.6,57H17.1c-2.9,0-5.3-2.4-5.3-5.3V21.9c0-1.4,0.5-2.7,1.5-3.7l4,4l0.1,29.6l48.2-0.4l3.7,4.1
        C68.3,56.5,67,57,65.6,57z"/></g><g><path class="st3" d="M69.3,55.5l-4-4l-0.1-29.6l-48.2,0.4l-3.7-4.1c1-1,2.3-1.5,3.7-1.5h48.5c2.9,0,5.3,2.4,5.3,5.3v29.8
        C70.9,53.2,70.3,54.5,69.3,55.5z"/></g><g><rect x="16.7" y="69.4" class="st3" width="12.3" height="5.6"/></g><g><rect x="35.3" y="69.4" class="st3" width="12.3" height="5.6"/></g><g><rect x="53.6" y="69.4" class="st3" width="12.3" height="5.6"/></g><g><rect x="16.8" y="84.8" class="st3" width="12.3" height="5.6"/></g><g><rect x="35.4" y="84.8" class="st3" width="12.3" height="5.6"/></g><g><rect x="53.7" y="84.8" class="st3" width="12.3" height="5.6"/></g><g><rect x="16.7" y="100.1" class="st3" width="12.3" height="5.6"/></g><g><rect x="35.3" y="100.1" class="st3" width="12.3" height="5.6"/></g><g><rect x="53.6" y="100.1" class="st3" width="12.3" height="5.6"/></g></g></svg>`,
        level: 2000,
        inactive: false,
        children: [
            {
                title: 'Terminal Virtual',
                path: '/pages/terminal-virtual',
                inactive: false,
                level: 2000,
                permissions: ["transacao_liberacao_online_visualizar"],
                disabled: true,
            },
            {
                title: 'Transações',
                path: '/pages/transacoes/list',
                inactive: false,
                level: 2000,
                permissions: ["transacao_visualizar"],
            },
            {
                title: 'Recebíveis',
                path: '/pages/recebiveis/calendar',
                inactive: false,
                level: 2000,
                permissions: ["transacao_recebivel_visualizar"],
            },
            {
                title: 'Vendedores',
                path: '/pages/dados-cadastrais/particular/list',
                level: 1000,
                inactive: false,
                permissions: ["vendedor_alterar"],
                disabled: true
            },
            {
                title: 'Vendedores',
                path: '/pages/dados-cadastrais/particular/create',
                inactive: false,
                level: 1000,
                disabled: true,
                permissions: ["vendedor_alterar"]
            },
            {
                title: 'Vendedores',
                path: '/pages/dados-cadastrais/particular/edit',
                inactive: false,
                level: 1000,
                disabled: true,
                permissions: ["vendedor_alterar"]
            },
            {
                title: 'Calendário',
                path: '/pages/recebiveis/calendar',
                inactive: false,
                level: 2000,
                disabled: true,
                permissions: ["transacao_recebivel_visualizar"],
            },
            {
                title: 'Listagem',
                path: '/pages/recebiveis/list',
                inactive: false,
                level: 2000,
                disabled: true,
                permissions: ["transacao_recebivel_visualizar"],
            },
            {
                title: 'Extrato',
                path: '/pages/recebiveis/list-by-day',
                inactive: false,
                level: 2000,
                disabled: true,
                permissions: ["transacao_recebivel_visualizar"],
            },
            {
                title: 'Detalhes',
                path: '/pages/recebiveis/detail',
                inactive: false,
                level: 2000,
                disabled: true,
                permissions: ["transacao_recebivel_visualizar"],
            }
        ]
    },
    {
        title: 'Simulador de Vendas',
        path: '/pages/simulador-vendas',
        icon: `<?xml version="1.0" encoding="utf-8"?><!-- Generator:Adobe Illustrator 23.0.3,SVG Export Plug-In . SVG Version:6 Build 0) --><svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve"><style type="text/css">.svg-container .st0{fill:#0063CF}.svg-container .st1{fill:#FF9E1B}.svg-container .st2{fill:#5B7F95}.svg-container .st3{fill:#1B365D}.svg-container .st4{fill:#00335E}</style><g><g><rect x="23.2" y="40.8" class="st3" width="81.6" height="6.1"/></g><g><rect x="23.2" y="81" class="st3" width="81.6" height="6.1"/></g><g><rect x="60.9" y="43.2" class="st3" width="6.1" height="81.6"/></g><g><path class="st2" d="M23.6,124.5c-2.3-2.3-3.5-5.3-3.5-8.4V11.9C20.1,5.4,25.5,0,32.1,0h63.8c2.9,0,5.7,1.1,7.9,3l-4.1,4.6
        c-1.1-0.9-2.4-1.5-3.9-1.5H32.1c-3.2,0-5.8,2.6-5.8,5.8v104.1c0,1.6,0.6,3,1.7,4.1L23.6,124.5z"/></g><g><path class="st3" d="M95.9,128H32.1c-3.2,0-6.2-1.2-8.4-3.5l4.3-4.3c1.1,1.1,2.6,1.7,4.1,1.7h63.8c3.2,0,5.8-2.6,5.8-5.8V11.9
        c0-1.7-0.7-3.3-2-4.4l4.1-4.6c2.6,2.3,4,5.5,4,8.9v104.1C107.9,122.6,102.5,128,95.9,128z"/></g><g><polygon class="st0" points="96.6,34.3 71.2,34.3 71.2,28.2 90.5,28.2 90.6,11.9 96.6,11.9 		"/></g><g><polygon class="st1" points="71.2,34.3 32.4,34.3 32.4,11.9 90.6,11.9 90.6,18 38.5,18 38.5,28.2 71.2,28.2 		"/></g><g><rect x="34.8" y="62.2" class="st1" width="17.1" height="4.1"/></g><g><rect x="75.9" y="62.2" class="st0" width="17.1" height="4.1"/></g><g><rect x="75.9" y="102.6" class="st0" width="17.1" height="4.1"/></g><g><rect x="41.3" y="55.7" class="st1" width="4.1" height="17.1"/></g><g><rect x="41.3" y="96.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -61.2838 61.3246)" class="st1" width="4.1" height="17.1"/></g><g><rect x="34.8" y="102.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -61.2838 61.3246)" class="st1" width="17.1" height="4.1"/></g><g><path class="st0" d="M84.3,100.2c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S85.7,100.2,84.3,100.2z"/></g><g><path class="st0" d="M84.3,113.8c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5C86.8,112.6,85.7,113.8,84.3,113.8
        z"/></g></g></svg>`,
        inactive: false,
        level: 4000,
    },
    {
        title: 'Gestão Financeira',
        path: '',
        icon: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" xml:space="preserve"><style type="text/css">.svg-container .st0{fill:#0063CF;}.svg-container .st1{fill:#FF9E1B;}.svg-container .st2{fill:#5B7F95;}.svg-container .st3{fill:#1B365D;}.svg-container .st4{fill:#00335E;</style><g><polygon class="st3" points="36.3,106.1 12.1,106.1 12.1,52 21,52 21,97.2 36.3,97.2 	"/><polygon class="st2" points="36.3,106.1 27.3,106.1 27.3,60.9 12.1,60.9 12.1,52 36.3,52 	"/><polygon class="st0" points="76.2,106.1 52.1,106.1 52.1,39.5 61,39.5 61,97.2 76.2,97.2 	"/><polygon class="st1" points="76.2,106.1 67.3,106.1 67.3,48.4 52.1,48.4 52.1,39.5 76.2,39.5 	"/><polygon class="st3" points="116.2,106.1 92,106.1 92,21.9 101,21.9 101,97.2 116.2,97.2 	"/><polygon class="st2" points="116.2,106.1 107.3,106.1 107.3,30.8 92,30.8 92,21.9 116.2,21.9 	"/><rect y="97.2" class="st0" width="128" height="9"/></g></svg>',
        inactive: false,
        level: 2000,
        permissions: ["dashboard"],
        children: [
            {
                title: 'Resumo',
                path: '/pages/dashboard/resumo',
                inactive: false,
                level: 2000,
                permissions: ["dashboard"],
            },
            {
                title: 'Faturamento',
                path: '/pages/dashboard/faturamento',
                inactive: false,
                level: 2000,
                permissions: ["dashboard"],
            },
            {
                title: 'Glosa',
                path: '/pages/dashboard/glosa',
                inactive: false,
                level: 2000,
                permissions: ["dashboard"],
            },
            {
                title: 'Pagamento',
                path: '/pages/dashboard/pagamento',
                inactive: false,
                level: 2000,
                permissions: ["dashboard"],
            },
            {
                title: 'Conciliação',
                path: '/pages/conciliacao',
                level: 2000,
                inactive: false,
                permissions: ["conciliacao_totais"]
            }
        ]
    },
    {
        title: 'Convênios',
        path: '/pages/dados-cadastrais/plano/create',
        icon: `<?xml version="1.0" encoding="utf-8"?><!-- Generator:Adobe Illustrator 23.0.3,SVG Export Plug-In . SVG Version:6 Build 0) --><svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve"><style type="text/css">.svg-container .st0{fill:#0063CF}.svg-container .st1{fill:#FF9E1B}.svg-container .st2{fill:#5B7F95}.svg-container .st3{fill:#1B365D}.svg-container .st4{fill:#00335E}</style><g><path class="st3" d="M8.4,27.6c0,0,0.9-1,1.4-1.1l-0.7-4.5l-3.8-1.3c-0.5,0.3-1,0.7-1.4,1.1L8.4,27.6z"/><path class="st3" d="M118.4,101.3c0,0,1-0.6,1.3-1.2l2.6,1.2l1.8,2.7l0.4,1.5c0,0-0.9,0.9-1.3,1.2l-5.1-3.2L118.4,101.3z"/><path class="st2" d="M116,102H12c-2.6,0-4.7-2.1-4.7-4.7V30.7c0-1.1,0.4-2.2,1.2-3.1l-4.5-5.8C1.4,24.1,0,27.3,0,30.7v66.6
		c0,6.6,5.4,12,12,12H116c2.6,0,5.2-0.9,7.2-2.4l-4.9-5.5C117.7,101.7,116.9,102,116,102z"/><path class="st3" d="M128,97.3V30.7c0-6.6-5.4-12-12-12H12c-2.4,0-4.7,0.7-6.6,2l4.5,5.8c0.7-0.3,1.4-0.5,2.1-0.5H116
		c2.6,0,4.7,2.1,4.7,4.7v66.6c0,1-0.3,2-1,2.9l4.9,5.5C126.8,103.4,128,100.4,128,97.3z"/><rect x="50.1" y="70.9" class="st3" width="60.8" height="7.6"/><rect x="70.6" y="79.8" class="st3" width="40.2" height="7.6"/><rect x="62.4" y="88.9" class="st3" width="48.4" height="7.6"/><rect x="50" y="88.9" class="st3" width="8.7" height="7.6"/><path class="st3" d="M31.8,66.4c-9.8,0-17.7-7.9-17.7-17.7S22.1,31,31.8,31s17.7,7.9,17.7,17.7c0,0,0,0,0,0
		C49.5,58.4,41.6,66.4,31.8,66.4z M31.8,37.1c-6.4,0-11.6,5.2-11.6,11.6s5.2,11.6,11.6,11.6s11.6-5.2,11.6-11.6l0,0
		C43.5,42.3,38.3,37.1,31.8,37.1z"/><rect x="88.5" y="42.9" class="st0" width="20.2" height="7.6"/><rect x="94.8" y="36.6" class="st1" width="7.6" height="20.2"/></g></svg>`,
        level: 2000,
        inactive: false,
        disabled: true,
    },
    {
        title: 'Convênios',
        path: '',
        icon: `<?xml version="1.0" encoding="utf-8"?><!-- Generator:Adobe Illustrator 23.0.3,SVG Export Plug-In . SVG Version:6 Build 0) --><svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve"><style type="text/css">.svg-container .st0{fill:#0063CF}.svg-container .st1{fill:#FF9E1B}.svg-container .st2{fill:#5B7F95}.svg-container .st3{fill:#1B365D}.svg-container .st4{fill:#00335E}</style><g><path class="st3" d="M8.4,27.6c0,0,0.9-1,1.4-1.1l-0.7-4.5l-3.8-1.3c-0.5,0.3-1,0.7-1.4,1.1L8.4,27.6z"/><path class="st3" d="M118.4,101.3c0,0,1-0.6,1.3-1.2l2.6,1.2l1.8,2.7l0.4,1.5c0,0-0.9,0.9-1.3,1.2l-5.1-3.2L118.4,101.3z"/><path class="st2" d="M116,102H12c-2.6,0-4.7-2.1-4.7-4.7V30.7c0-1.1,0.4-2.2,1.2-3.1l-4.5-5.8C1.4,24.1,0,27.3,0,30.7v66.6
		c0,6.6,5.4,12,12,12H116c2.6,0,5.2-0.9,7.2-2.4l-4.9-5.5C117.7,101.7,116.9,102,116,102z"/><path class="st3" d="M128,97.3V30.7c0-6.6-5.4-12-12-12H12c-2.4,0-4.7,0.7-6.6,2l4.5,5.8c0.7-0.3,1.4-0.5,2.1-0.5H116
		c2.6,0,4.7,2.1,4.7,4.7v66.6c0,1-0.3,2-1,2.9l4.9,5.5C126.8,103.4,128,100.4,128,97.3z"/><rect x="50.1" y="70.9" class="st3" width="60.8" height="7.6"/><rect x="70.6" y="79.8" class="st3" width="40.2" height="7.6"/><rect x="62.4" y="88.9" class="st3" width="48.4" height="7.6"/><rect x="50" y="88.9" class="st3" width="8.7" height="7.6"/><path class="st3" d="M31.8,66.4c-9.8,0-17.7-7.9-17.7-17.7S22.1,31,31.8,31s17.7,7.9,17.7,17.7c0,0,0,0,0,0
		C49.5,58.4,41.6,66.4,31.8,66.4z M31.8,37.1c-6.4,0-11.6,5.2-11.6,11.6s5.2,11.6,11.6,11.6s11.6-5.2,11.6-11.6l0,0
		C43.5,42.3,38.3,37.1,31.8,37.1z"/><rect x="88.5" y="42.9" class="st0" width="20.2" height="7.6"/><rect x="94.8" y="36.6" class="st1" width="7.6" height="20.2"/></g></svg>`,
        level: 2000,
        inactive: false,
        children: [
            {
                title: 'Faturas',
                path: '/pages/lote/list',
                inactive: false,
                level: 2000,
                permissions: ["fatura_visualizar"],
            },
        ]
    },
    {
        title: 'Indique um amigo',
        path: '/pages/referral',
        icon: `<?xml version="1.0" encoding="utf-8"?><!-- Generator:Adobe Illustrator 23.0.3,SVG Export Plug-In . SVG Version:6 Build 0) --><svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve"><style type="text/css">.svg-container .st0{fill:#0063CF}.svg-container .st1{fill:#FF9E1B}.svg-container .st2{fill:#5B7F95}.svg-container .st3{fill:#1B365D}.svg-container .st4{fill:#00335E}</style><g><g><g><path class="st2" d="M64,125c-1.5,0-2.7-1.2-2.7-2.7v-9.5c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7v9.5
        C66.7,123.8,65.5,125,64,125z"/></g><g><path class="st2" d="M64,17.9c-1.5,0-2.7-1.2-2.7-2.7V5.7C61.3,4.2,62.5,3,64,3c1.5,0,2.7,1.2,2.7,2.7v9.5
        C66.7,16.7,65.5,17.9,64,17.9z"/></g><g><path class="st2" d="M12.7,66.7H3.2c-1.5,0-2.7-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7h9.5c1.5,0,2.7,1.2,2.7,2.7
        C15.4,65.5,14.2,66.7,12.7,66.7z"/></g><g><path class="st2" d="M124.8,66.7h-9.5c-1.5,0-2.7-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7h9.5c1.5,0,2.7,1.2,2.7,2.7
        C127.5,65.5,126.3,66.7,124.8,66.7z"/></g><g><path class="st2" d="M108.9,111.6c-0.7,0-1.4-0.3-1.9-0.8l-6.7-6.7c-1.1-1.1-1.1-2.8,0-3.8c1.1-1,2.8-1,3.8,0l6.7,6.7
        c1.1,1.1,1.1,2.8,0,3.8C110.3,111.3,109.6,111.6,108.9,111.6z"/></g><g><path class="st2" d="M25.8,28.5c-0.7,0-1.4-0.3-1.9-0.8L17.2,21c-1.1-1.1-1.1-2.8,0-3.8c1.1-1,2.8-1,3.8,0l6.7,6.7
        c1.1,1.1,1.1,2.8,0,3.8C27.2,28.2,26.5,28.5,25.8,28.5z"/></g><g><path class="st2" d="M102.2,28.5c-0.7,0-1.4-0.3-1.9-0.8c-1.1-1.1-1.1-2.8,0-3.8l6.7-6.7c1.1-1,2.8-1,3.8,0
        c1.1,1.1,1.1,2.8,0,3.8l-6.7,6.7C103.6,28.2,102.9,28.5,102.2,28.5z"/></g><g><path class="st2" d="M19.1,111.6c-0.7,0-1.4-0.3-1.9-0.8c-1.1-1.1-1.1-2.8,0-3.8l6.7-6.7c1.1-1,2.8-1,3.8,0
        c1.1,1.1,1.1,2.8,0,3.8l-6.7,6.7C20.5,111.3,19.8,111.6,19.1,111.6z"/></g></g><g><g><path class="st0" d="M64,100.1l-2.4-7.6c0,0,7.2-6.2,9.9-8.5c7.1-6.1,13.3-11.3,17.5-16.3c4.3-5.1,6.3-9.8,6.3-14.7
        c0-4.5-1.5-8.6-4.2-11.6c-2.7-3.1-6.6-4.8-10.7-4.8c-4.6,0-8.6,2.2-11.7,6.5c-0.5,0.7-1,1.5-1.5,2.5c-1,1.8-3.2,2.5-5,1.5
        c-1.8-1-2.5-3.2-1.5-5c0.7-1.2,1.3-2.3,2-3.2c3.1-4.4,8.8-9.7,17.7-9.7c6.3,0,12.1,2.6,16.3,7.3c3.9,4.4,6,10.3,6,16.5
        c0,6.8-2.6,13-8,19.5c-4.6,5.4-11,10.9-18.4,17.2C73.6,91.8,64,100.1,64,100.1z"/></g><g><path class="st1" d="M64,100.1c0,0-9.6-8.2-12.4-10.5c-7.4-6.3-13.8-11.7-18.4-17.2c-5.5-6.5-8-12.7-8-19.4
        c0-6.2,2.1-12.1,6-16.5c4.2-4.7,9.9-7.3,16.3-7.3c8.9,0,14.6,5.3,17.7,9.7c0.7,1,1.4,2.1,2,3.2c1,1.8,0.3,4.1-1.5,5
        c-1.8,1-4.1,0.3-5-1.5c-0.4-0.8-0.9-1.6-1.5-2.4c-3.1-4.3-7-6.6-11.7-6.6c-4.2,0-8,1.7-10.7,4.8c-2.7,3.1-4.2,7.2-4.2,11.6
        c0,4.9,2,9.6,6.3,14.7c4.2,5,10.3,10.2,17.5,16.3c2.7,2.3,7.6,6.5,7.6,6.5V100.1z"/></g></g></g></svg>`,
        inactive: false,
        level: 4000,
    },
    {
        title: 'Dados Cadastrais',
        path: '',
        icon: '/assets/img/icons/cadastro.svg',
        inactive: false,
        level: 3000,
        disabled: true,
        children: [
            // item desabilitado do menu, criado apenas para o breadcrumb
            {
                title: 'Criação Vínculo',
                path: '/pages/dados-cadastrais/unidade/create',
                inactive: false,
                level: 1000,
                disabled: true,
                permissions: ["vendedor_alterar"]
            },
            {
                title: 'Edição Particular',
                path: '/pages/dados-cadastrais/unidade/edit',
                inactive: false,
                level: 1000,
                disabled: true,
                permissions: ["vendedor_alterar"]
            },
            // {
            //     title: 'Acordo Operacional',
            //     path: '/pages/dados-cadastrais/unidade/acordo-operacional',
            //     inactive: false,
            //     disabled: true
            // },
            {
                title: 'Conta',
                path: '/pages/dados-cadastrais/conta/edit',
                inactive: false,
                level: 1000,
                disabled: true,
                permissions: ["vendedor_alterar"]
            },
            {
                title: 'Criação de Conta',
                path: '/pages/dados-cadastrais/conta/create',
                inactive: false,
                level: 1000,
                disabled: true,
                permissions: ["vendedor_alterar"]
            },
            {
                title: 'Listagem de Conta',
                path: '/pages/dados-cadastrais/conta/list',
                inactive: false,
                level: 1000,
                disabled: true,
                permissions: ["vendedor_alterar"]
            },
            {
                title: 'Convênios',
                path: '/pages/dados-cadastrais/plano/list',
                inactive: false,
                level: 3000,
                permissions: ["medico_plano_saude_visualizar"]
            },
            // item desabilitado do menu, criado apenas para o breadcrumb
            {
                title: 'Convênios',
                path: '/pages/dados-cadastrais/plano/create',
                inactive: false,
                level: 3000,
                disabled: true,
                permissions: ["medico_plano_saude_visualizar"]
            },
            {
                title: 'Convênios',
                path: '/pages/dados-cadastrais/plano/edit',
                inactive: false,
                level: 3000,
                disabled: true,
                permissions: ["medico_plano_saude_visualizar"]
            },
            {
                title: 'Particular',
                path: '/pages/dados-cadastrais/particular/list',
                level: 1000,
                inactive: false,
                permissions: ["vendedor_alterar"]
            },
            // item desabilitado do menu, criado apenas para o breadcrumb
            {
                title: 'Particular',
                path: '/pages/dados-cadastrais/particular/create',
                inactive: false,
                level: 1000,
                disabled: true,
                permissions: ["vendedor_alterar"]
            },
            {
                title: 'Particular',
                path: '/pages/dados-cadastrais/particular/edit',
                inactive: false,
                level: 1000,
                disabled: true,
                permissions: ["vendedor_alterar"]
            }
            // item desabilitado do menu, criado apenas para o breadcrumb
        ]
    },
    {
        title: 'Terminal Virtual',
        path: '/pages/terminal-virtual',
        icon: '/assets/img/icons/terminal_virtual.svg',
        inactive: false,
        level: 4000,
        disabled: true,
        permissions: ["transacao_liberacao_online_visualizar"]
    },
    // item desabilitado do menu, criado apenas para o breadcrumb
    {
        title: 'Permissionamento',
        path: '/pages/permissionamento',
        inactive: false,
        level: 4000,
        disabled: true
    },
    {
        title: 'Cadastro Concluído',
        path: '/pages/dados-cadastrais/cadastro-completo/cadastro-geral',
        inactive: false,
        level: 4000,
        disabled: true
    },
    {
        title: 'Dashboard',
        path: '/pages/dashboard',
        inactive: false,
        level: 2000,
        disabled: true
    },
    {
        title: 'Antecipação de Faturas',
        path: '/pages/antecipacao-fatura/validation',
        inactive: false,
        level: 2000,
        disabled: true
    },
    {
        title: 'Antecipação de Faturas',
        path: 'pages/antecipacao-fatura/validation',
        inactive: false,
        level: 2000,
        disabled: true
    },
    {
        title: 'Antecipação de Faturas',
        path: '/pages/antecipacao-fatura/validation-all',
        inactive: false,
        level: 2000,
        disabled: true
    },
    {
        title: 'Antecipação de Faturas',
        path: '/pages/antecipacao-fatura/confirmation',
        inactive: false,
        level: 2000,
        disabled: true
    },
    {
        title: 'Detalhes',
        path: 'pages/transacoes/detail/',
        icon: '/assets/img/icons/icone-historico-lotes.png',
        inactive: false,
        level: 2000,
        disabled: true
    },
    {
        title: 'Detalhes',
        path: 'pages/lote/detail/',
        icon: '/assets/img/icons/icone-historico-lotes.png',
        inactive: false,
        level: 2000,
        disabled: true
    },

    {
        title: 'Problema no Pagamento',
        path: 'pages/erro-assinatura',
        inactive: false,
        level: 2000,
        disabled: true
    },
    // item desabilitado do menu, criado apenas para o breadcrumb
    {
        title: 'Antecipações',
        path: '',
        icon: `<?xml version="1.0" encoding="utf-8"?><svg width="16" height="20" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6.33331H2.66667V7.66665H4V6.33331ZM6.66667 6.33331H5.33333V7.66665H6.66667V6.33331ZM9.33333 6.33331H8V7.66665H9.33333V6.33331ZM10.6667 1.66665H10V0.333313H8.66667V1.66665H3.33333V0.333313H2V1.66665H1.33333C0.593333 1.66665 0.00666666 2.26665 0.00666666 2.99998L0 12.3333C0 13.0666 0.593333 13.6666 1.33333 13.6666H10.6667C11.4 13.6666 12 13.0666 12 12.3333V2.99998C12 2.26665 11.4 1.66665 10.6667 1.66665ZM10.6667 12.3333H1.33333V4.99998H10.6667V12.3333Z" fill="#0063CF"/>
            </svg>`,
        inactive: false,
        level: 2000,
        children: [
            {
                title: 'Plano de Saúde',
                path: 'pages/antecipacoes/antecipacao-fatura',
                inactive: false,
                level: 2000,
                permissions: ["medico_plano_saude_visualizar"],
            },
            {
                title: 'Cartão de Crédito',
                path: 'pages/antecipacoes/antecipacao-transacao',
                inactive: true,
                level: 2000,
                permissions: ["transacao_antecipacao_incluir"],
            }
        ]
    },
]
